import { useRolesQuery } from "../hooks";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { RoleRead } from "../api/roles";

interface Props {
  orgId: string;
  type: "org" | "deal";
  onChange: (roles: RoleRead[]) => void;
}

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function RolesMultiSelect({ orgId, type, onChange }: Props) {
  const rolesQuery = useRolesQuery(orgId, type);
  const [selectedRoles, setSelectedRoles] = useState<RoleRead[]>([]);

  if (rolesQuery.isLoading) {
    return <p>Loading</p>;
  }

  if (rolesQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <Listbox
      value={selectedRoles}
      onChange={(roles) => {
        setSelectedRoles(roles);
        onChange(roles);
      }}
      multiple
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="block truncate">
                {selectedRoles.length ? (
                  selectedRoles.map((role) => role.role).join(", ")
                ) : (
                  <span className="text-gray-400">Select role(s)</span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y-[1px]">
                {rolesQuery.data.map((role) => (
                  <Listbox.Option
                    key={role.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-8 pr-4"
                      )
                    }
                    value={role}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {role.role}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}

                        <div>
                          <p
                            className={classNames(
                              active ? "text-indigo-100" : "text-gray-500",
                              "font-size-xs text-xs"
                            )}
                          >
                            {role.description}
                          </p>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
