import { useParams } from "react-router-dom";
import { useFileTableGroupMergeQuery } from "../../../hooks/fileTableGroupMerges";
import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import { APIError } from "../../../api/shared";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FileTableGroupEntry,
  FileTableGroupMergeData,
  FileTableMergeData,
  FileTableMergeFile,
  Table,
} from "../../../api/fileTableGroupMerges";
import info from "../../../info";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import CreateFileTableGroupMergeExportButton from "./CreateFileTableGroupMergeExportButton";
import TabLayout from "../../TabLayout";
import { useDealQuery } from "../../../hooks";

type URLParams = {
  orgId: string;
  dealId: string;
  fileTableGroupMergeId: string;
};

function CopyToClipboard({ table }: { table: Table }) {
  const [showCopied, setShowCopied] = useState(false);
  const posthog = usePostHog();
  const { user } = useAuth0();

  const tableToText = (table: Table) => {
    const array = [];
    for (let i = 0; i < table.row_count; i++) {
      const row = [];
      for (let j = 0; j < table.column_count; j++) {
        row.push("");
      }
      array.push(row);
    }
    for (const cell of table.cells) {
      array[cell.row_index][cell.column_index] = cell.content;
    }
    return array
      .map((row) => row.map((cell) => `"${cell}"`).join("\t"))
      .join("\n");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tableToText(table));
    setShowCopied(true);
    if (user) {
      posthog.capture("copy_file_table", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
    setTimeout(() => setShowCopied(false), 2000);
  };

  if (showCopied) {
    return (
      <div className="flex flex-row items-center space-x-2 hover:cursor-pointer">
        <CheckIcon className="h-4 w-4 text-gray-500" />
        <p className="text-xs text-gray-500">Copied!</p>
      </div>
    );
  }

  return (
    <div
      className="flex flex-row items-center space-x-2 hover:cursor-pointer"
      onClick={copyToClipboard}
    >
      <ClipboardIcon className="h-4 w-4 text-gray-500" />
      <p className="text-xs text-gray-500">Copy table</p>
    </div>
  );
}

function TableRender({ table }: { table: Table | null }) {
  if (table === null) {
    return (
      <div className="prose prose-sm">
        <p>Table loading...</p>
      </div>
    );
  }
  const array = [];
  for (let i = 0; i < table.row_count; i++) {
    const row = [];
    for (let j = 0; j < table.column_count; j++) {
      row.push("");
    }
    array.push(row);
  }

  for (const cell of table.cells) {
    array[cell.row_index][cell.column_index] = cell.content;
  }

  return (
    <div className="outline outline-1 outline-gray-100 rounded-md overflow-auto">
      <table className="w-full">
        <tbody>
          {array.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((cell, j) => (
                <td key={j} className="px-2">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CheckBox({
  state,
  onClick,
}: {
  state: "checked" | "unchecked" | "indeterminate";
  onClick: () => void;
}) {
  if (state === "checked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded items-center justify-center bg-indigo-600 hover:cursor-pointer"
      >
        <CheckIcon className="h-3 w-3 text-white" />
      </div>
    );
  }
  if (state === "unchecked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded border border-1 border-gray-300 items-center justify-center hover:cursor-pointer"
       />
    );
  }
  return (
    <div
      onClick={() => onClick()}
      className="flex h-4 w-4 rounded border border-1 border-gray-300 items-center justify-center hover:cursor-pointer"
    >
      <div className="bg-indigo-600 h-2 w-2 rounded-sm" />
    </div>
  );
}

function MergedTableGroupSources({
  tableGroup,
  setFileViewerParams,
}: {
  tableGroup: FileTableGroupEntry[];
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    <div className="prose prose-sm mt-3">
      <p>Sources:</p>
      <ul className="">
        {tableGroup.map((fileTable, index) => {
          if (fileTable.page_number === null) {
            return (
              <li
                key={index}
                className="hover:cursor-pointer"
                onClick={() =>
                  setFileViewerParams({
                    fileId: fileTable.file_id,
                    pageNumber: 1,
                    websiteId: null,
                    webpageId: null,
                    waiting: true,
                  })
                }
              >
                <p className="text-xs text-blue-500 my-0 ">
                  {fileTable.file_name}
                </p>
              </li>
            );
          }
          return (
            <li
              key={index}
              className="hover:cursor-pointer"
              onClick={() =>
                setFileViewerParams({
                  fileId: fileTable.file_id,
                  pageNumber: fileTable.page_number,
                  websiteId: null,
                  webpageId: null,
                  waiting: true,
                })
              }
            >
              <p className="text-xs text-blue-500 my-0">
                {fileTable.file_name}, Page {fileTable.page_number}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MergedTableSources({
  files,
  setFileViewerParams,
}: {
  files: FileTableMergeFile[];
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    <div className="prose prose-sm mt-3">
      <p>Sources:</p>
      <ul className="">
        {files.map((file, index) => {
          return (
            <li
              key={index}
              className="hover:cursor-pointer"
              onClick={() =>
                setFileViewerParams({
                  fileId: file.id,
                  pageNumber: 1,
                  websiteId: null,
                  webpageId: null,
                  waiting: true,
                })
              }
            >
              <p className="text-xs text-blue-500 my-0 ">{file.name}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MergedTableOutliers({
  outliers,
  setFileViewerParams,
}: {
  outliers: FileTableMergeFile[];
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    <div className="prose prose-sm mt-3">
      <p>Excluded:</p>
      <ul className="">
        {outliers.map((file, index) => {
          return (
            <li
              key={index}
              className="hover:cursor-pointer"
              onClick={() =>
                setFileViewerParams({
                  fileId: file.id,
                  pageNumber: 1,
                  websiteId: null,
                  webpageId: null,
                  waiting: true,
                })
              }
            >
              <p className="text-xs text-blue-500 my-0 ">{file.name}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface TablesProps {
  orgId: string;
  dealId: string;
  fileTableGroupMergeId: string;
  fileTableGroupMergeData: FileTableGroupMergeData;
  setFileViewerParams: (params: FileViewerParams) => void;
}

function Tables({
  orgId,
  dealId,
  fileTableGroupMergeId,
  fileTableGroupMergeData,
  setFileViewerParams,
}: TablesProps) {
  const tableGroups = fileTableGroupMergeData.table_groups;
  const mergedTables = fileTableGroupMergeData.merged_tables
    ? fileTableGroupMergeData.merged_tables
    : tableGroups.map(() => null);
  const [selectedTables, setSelectedTables] = useState<Set<number>>(new Set());

  const toggleAllTables = () => {
    if (selectedTables.size > tableGroups.length / 2) {
      setSelectedTables(new Set());
    } else {
      setSelectedTables(new Set(tableGroups.map((fileTable, index) => index)));
    }
  };

  const toggleTable = (tableNumber: number) => {
    if (selectedTables.has(tableNumber)) {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.delete(tableNumber);
      setSelectedTables(newSelectedTables);
    } else {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.add(tableNumber);
      setSelectedTables(newSelectedTables);
    }
  };

  const tableGroupsAndMergedTables = tableGroups.map((tableGroup, index) => {
    return {
      tableGroup,
      mergedTable: mergedTables[index],
    };
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b-[1px] border-gray-200 pb-2 mb-2 ml-6 items-center">
        <div className="ml-1 mr-3">
          <CheckBox
            state={
              selectedTables.size === tableGroups.length
                ? "checked"
                : selectedTables.size === 0
                ? "unchecked"
                : "indeterminate"
            }
            onClick={toggleAllTables}
          />
        </div>
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">
          Tables
        </h1>
        <CreateFileTableGroupMergeExportButton
          orgId={orgId}
          dealId={dealId}
          fileTableGroupMergeId={fileTableGroupMergeId}
          tableNumbers={Array.from(selectedTables)}
        />
      </div>
      <div className="flex-grow overflow-auto prose-sm max-w-none">
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          {tableGroupsAndMergedTables.map(
            ({ tableGroup, mergedTable }, index) => (
              <li key={index} className="">
                <div className="flex flex-row items-center pb-2">
                  <div className="mr-3">
                    <CheckBox
                      state={
                        selectedTables.has(index) ? "checked" : "unchecked"
                      }
                      onClick={() => toggleTable(index)}
                    />
                  </div>

                  {/* {fileTable.page_number !== null && (
                  <p
                    className="text-sm font-semibold hover:cursor-pointer text-blue-500"
                    onClick={() => {
                      setFileViewerParams({
                        fileId: fileId,
                        pageNumber: fileTable.page_number,
                        websiteId: null,
                        webpageId: null,
                        waiting: true,
                      });
                    }}
                  >
                    Table {fileTable.number + 1}, Page {fileTable.page_number}
                  </p>
                )} */}
                  <p className="text-sm font-semibold text-gray-900">
                    Table {index + 1}
                  </p>
                  <div className="ml-auto">
                    {mergedTable !== null && (
                      <CopyToClipboard table={mergedTable.table} />
                    )}
                  </div>
                </div>
                <TableRender table={mergedTable ? mergedTable.table : null} />
                <MergedTableGroupSources
                  tableGroup={tableGroup}
                  setFileViewerParams={setFileViewerParams}
                />
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}

interface TableViewProps {
  orgId: string;
  dealId: string;
  fileTableGroupMergeId: string;
  fileTableMergeData: FileTableMergeData;
  setFileViewerParams: (params: FileViewerParams) => void;
}

function TableView({
  orgId,
  dealId,
  fileTableGroupMergeId,
  fileTableMergeData,
  setFileViewerParams,
}: TableViewProps) {
  const outlierIndexes =
    fileTableMergeData.outliers !== null ? fileTableMergeData.outliers : [];
  const files = fileTableMergeData.files.filter(
    (file, index) => outlierIndexes.includes(index) === false
  );
  const outliers = fileTableMergeData.files.filter(
    (file, index) => outlierIndexes.includes(index) === true
  );
  const mergedTable = fileTableMergeData.merged_table;

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b-[1px] border-gray-200 pb-2 mb-2 ml-6 items-center">
        <div className="ml-1 mr-3" />
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">Table</h1>
        <CreateFileTableGroupMergeExportButton
          orgId={orgId}
          dealId={dealId}
          fileTableGroupMergeId={fileTableGroupMergeId}
          tableNumbers={[0]}
        />
      </div>
      <div className="flex-grow overflow-auto prose-sm max-w-none">
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <li key={0} className="">
            <div className="flex flex-row items-center pb-2">
              <div className="mr-3" />

              {/* {fileTable.page_number !== null && (
                  <p
                    className="text-sm font-semibold hover:cursor-pointer text-blue-500"
                    onClick={() => {
                      setFileViewerParams({
                        fileId: fileId,
                        pageNumber: fileTable.page_number,
                        websiteId: null,
                        webpageId: null,
                        waiting: true,
                      });
                    }}
                  >
                    Table {fileTable.number + 1}, Page {fileTable.page_number}
                  </p>
                )} */}
              <div className="ml-auto">
                {mergedTable !== null && (
                  <CopyToClipboard table={mergedTable} />
                )}
              </div>
            </div>
            <TableRender table={mergedTable} />
            <MergedTableSources
              files={files}
              setFileViewerParams={setFileViewerParams}
            />
            {outlierIndexes.length > 0 && (
              <MergedTableOutliers
                outliers={outliers}
                setFileViewerParams={setFileViewerParams}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

function _FileTableGroupMerge({
  orgId,
  dealId,
  fileTableGroupMergeId,
}: {
  orgId: string;
  dealId: string;
  fileTableGroupMergeId: string;
}) {
  const fileTableGroupMergeQuery = useFileTableGroupMergeQuery(
    orgId,
    dealId,
    fileTableGroupMergeId
  );
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  if (fileTableGroupMergeQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Loading file tables...</p>
        </ul>
      </div>
    );
  }

  if (
    fileTableGroupMergeQuery.error instanceof APIError &&
    fileTableGroupMergeQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to view this file."}
          </p>
        </ul>
      </div>
    );
  }

  if (fileTableGroupMergeQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing file tables.
          </p>
        </ul>
      </div>
    );
  }

  const data = fileTableGroupMergeQuery.data.data;
  return (
    <div className="flex flex-row h-[calc(100vh-9.5rem)] sm:h-[calc(100vh-5.5rem)]">
      <div className="w-1/2 h-full ">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="w-1/2 h-full ">
        {data.mode === "file_table_group_merge" && (
          <Tables
            orgId={orgId}
            dealId={dealId}
            fileTableGroupMergeId={fileTableGroupMergeId}
            fileTableGroupMergeData={data}
            setFileViewerParams={setFileViewerParams}
          />
        )}
        {data.mode === "file_table_merge" && (
          <TableView
            orgId={orgId}
            dealId={dealId}
            fileTableGroupMergeId={fileTableGroupMergeId}
            fileTableMergeData={data}
            setFileViewerParams={setFileViewerParams}
          />
        )}
      </div>
    </div>
  );
}

export default function FileTableGroupMerge() {
  const { orgId, dealId, fileTableGroupMergeId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const fileTableGroupMergeQuery = useFileTableGroupMergeQuery(
    orgId,
    dealId,
    fileTableGroupMergeId
  );

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Table Aggregations",
          href: `/orgs/${orgId}/deals/${dealId}/file-table-group-merges`,
        },
        { name: fileTableGroupMergeQuery.data?.name || "...", href: null },
      ]}
    >
      <div className="p-4">
        <_FileTableGroupMerge
          orgId={orgId}
          dealId={dealId}
          fileTableGroupMergeId={fileTableGroupMergeId}
        />
      </div>
    </TabLayout>
  );
}
