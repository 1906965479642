import {
  File,
  TableAggregationGroup,
  TablePointer,
} from "../../../../../api/tableAggregationLayouts";
import { createPortal } from "react-dom";
import { Table } from "../../../../../api/tableAggregationLayouts";
import {
  classNames,
  useScale,
  useDragItem,
  removeEmptyColumnsAndGroups,
} from "./utils";
import { DragItemType } from "./types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";

function tableToArray(table: Table) {
  const array = Array(table.row_count)
    .fill(null)
    .map(() => Array(table.column_count).fill(""));
  table.cells.forEach((cell) => {
    array[cell.row_index][cell.column_index] = cell.content;
  });
  return array;
}

function deleteTableItem(
  tableItem: { groupIndex: number; columnIndex: number; rowIndex: number },
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>
) {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this table?"
  );
  if (!confirmDelete) return;
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));
    newLayout[tableItem.groupIndex].columns[
      tableItem.columnIndex
    ].tables.splice(tableItem.rowIndex, 1);
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

// Render a html table from a FileTable
function TableAggregationTableRenderInner({
  files,
  tablePointer,
  groupIndex,
  columnIndex,
  rowIndex,
  setLayout,
}: {
  files: File[];
  tablePointer: TablePointer;
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const file = files[tablePointer.file_index];
  const table = file.tables[tablePointer.table_index].table;
  const tableArray = tableToArray(table);
  return (
    <div className="outline outline-1 outline-gray-400 rounded-md overflow-auto relative group">
      {/* on parent hover show cross in top left hand */}
      <div className="absolute top-2 left-2 right-2 bg-gray-100 border border-gray-300 hidden  w-[calc(100%)-1rem] rounded-md  group-hover:flex flex-row items-start shadow-sm">
        <button
          className="p-2"
          onClick={() =>
            deleteTableItem({ groupIndex, columnIndex, rowIndex }, setLayout)
          }
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
        <h1 className="text-sm" title={file.name}>
          {file.name}
        </h1>
      </div>
      <table className="w-full text-xs">
        <tbody>
          {tableArray.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((cell, j) => (
                <td key={j} className="px-2 truncate">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TableAggregationTableRenderPreview(props: {
  files: File[];
  tablePointer: TablePointer;
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { scaleStyle } = useScale();
  return (
    <div className={classNames(scaleStyle, "opacity-40 origin-top-left")}>
      <TableAggregationTableRenderInner {...props} />
    </div>
  );
}

export default function TableAggregationTableRender(props: {
  files: File[];
  tablePointer: TablePointer;
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { ref, itemState } = useDragItem({
    type: DragItemType.TABLE,
    groupIndex: props.groupIndex,
    columnIndex: props.columnIndex,
    rowIndex: props.rowIndex,
  });
  return (
    <div ref={ref}>
      <div className={itemState.type === "dragging" ? "opacity-60" : ""}>
        <TableAggregationTableRenderInner {...props} />
      </div>
      {itemState.type === "preview"
        ? createPortal(
            <TableAggregationTableRenderPreview {...props} />,
            itemState.container
          )
        : null}
    </div>
  );
}
