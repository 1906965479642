import React from 'react';

interface SkeletonProps {
    width: string;
    height: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ width, height }) => {
    return (
        <div
            className={`bg-gray-200 rounded animate-pulse`}
            style={{ width, height }}
        />
    );
};

export default Skeleton;