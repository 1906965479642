import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  FileTableGroupMergeCreate,
  createFileTableGroupMerge,
  deleteFiletableGroupMerge,
  listFileTableGroupMerges,
  readFileTableGroupMerge,
} from "../api/fileTableGroupMerges";

export const useFileTableGroupMergesQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "file-table-group-merges"],
    queryFn: () => listFileTableGroupMerges(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((file) => file.status === "pending")) {
        return 10000;
      }
      return false;
    },
  });
};

export const useFileTableGroupMergeQuery = (
  orgId: string,
  dealId: string,
  fileTableGroupMergeId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "file-table-group-merges",
      fileTableGroupMergeId,
    ],
    queryFn: () =>
      readFileTableGroupMerge(user, orgId, dealId, fileTableGroupMergeId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.status === "pending") {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateFileTableGroupMergeMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: FileTableGroupMergeCreate) =>
      createFileTableGroupMerge(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "file-table-group-merges"],
      });
    },
  });
};


export const useDeleteFileTableGroupMergeMutation = (orgId: string, dealId: string) => {
    const { user } = useAuth();
    const queryClient = useQueryClient();
    assertUserIsAuthenticated(user);
    return useMutation({
      mutationFn: ({ fileTableGroupMergeId }: { fileTableGroupMergeId: string }) =>
        deleteFiletableGroupMerge(user, orgId, dealId, fileTableGroupMergeId),
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({
          queryKey: [orgId, "deals", dealId, "file-table-group-merges"],
        });
      },
    });
  };