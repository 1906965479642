import { Dispatch, SetStateAction } from "react";
import { useWizard } from "react-use-wizard";
import { useDealFilesQuery } from "../../../../hooks";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export interface SelectedFile {
  name: string;
  id: string;
}

export default function SelectFilesPage({
  orgId,
  dealId,
  selectedFiles,
  setSelectedFiles,
}: {
  orgId: string;
  dealId: string;
  selectedFiles: SelectedFile[];
  setSelectedFiles: Dispatch<SetStateAction<SelectedFile[]>>;
}) {
  const { nextStep } = useWizard();
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const navigate = useNavigate();

  const handleSelectFile = (name: string, id: string) => {
    if (id === "") {
      return;
    }
    setSelectedFiles((p) => [...p, { name: name, id: id }]);
  };

  const handleUnselectFile = (id: string) => {
    console.log(id);
    setSelectedFiles((p) => p.filter((file) => file.id !== id));
  };

  const handleNextStep = () => {
    if (selectedFiles.length === 0) {
      alert("Please select at least one file.");
      return;
    }
    nextStep();
  };

  if (filesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (filesQuery.isError) {
    return <div>Error loading files.</div>;
  }

  let files = filesQuery.data.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  files = files.filter(
    (file) => !selectedFiles.find((selectedFile) => selectedFile.id === file.id)
  );

  return (
    <div>
      <h1 className="text-sm font-semibold">Select Files</h1>
      <select
        onChange={(e) =>
          handleSelectFile(e.target.selectedOptions[0].text, e.target.value)
        }
        className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        <option value={""}>Select a file...</option>
        {files.map((file) => (
          <option key={file.id} value={file.id}>
            {file.name}
          </option>
        ))}
      </select>
      <ul className="mt-4 space-y-2">
        {selectedFiles.map((file) => (
          <div key={file.id} className="flex flex-row grow bg-gray-100 px-2 py-1 rounded-md">
            <li className="truncate text-sm" title={file.name}>
              {file.name}
            </li>
            <div className="grow" />
            <button onClick={() => handleUnselectFile(file.id)}>
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        ))}
      </ul>
      <div className="mt-4 flex flex-row grow justify-end space-x-2">
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() =>
            navigate(`/orgs/${orgId}/deals/${dealId}/table-aggregations`)
          }
        >
          Cancel
        </button>
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => handleNextStep()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
