
import usePromoteToDealMutation from '../hooks/company';

import { TrashIcon } from '@heroicons/react/20/solid';
import { checkForPassword, hasPermission } from "../../../utils";
import { useCreateDealFileMutation } from "../../../hooks";
import { useUserDealPermissionsQuery } from "../../../hooks/userDealRoles";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { RegistryDocument } from '../api/listRegistryDocuments';


interface UploadFilesPixelProps {
    orgId: string;
    dealId: string;
    files: FileList;
    onUploadComplete: () => void;
}

const UploadFilesPixel = ({ orgId, dealId, files, onUploadComplete }: UploadFilesPixelProps) => {
    const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
    const mutation = useCreateDealFileMutation(orgId, dealId);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const uploadFiles = async () => {
            if (!files || files.length === 0) { console.log("No files to upload"); return; }
            if (permissionsQuery.isLoading || !permissionsQuery.data) { console.log("Permissions not loaded"); return; }
            if (uploading) { console.log("Already uploading"); return; }

            setUploading(true);
            if (!hasPermission(permissionsQuery, "files.create")) { console.log("No permission to upload files"); return; }

            for (const file of Array.from(files)) {
                try {
                    console.log("Uploading file:", file);
                    const password = await checkForPassword(file);
                    mutation.mutate({
                        file: file,
                        password: password,
                    });
                } catch (error) {
                    console.error(`"${file.name}" could not be uploaded.`, error);
                }
            }
            onUploadComplete();
        };

        uploadFiles();
    }, [files, permissionsQuery.isLoading]);

    return <></>;
};

interface CreateButtonProps {
    orgId: string;
    files: FileList | null;
    companyId: string;
    countryCode: string;
    registryDocuments: RegistryDocument[];
    legalName?: string;
    website: string | null;
}

const CreateButton = ({ orgId, files, companyId, countryCode, registryDocuments, legalName, website }: CreateButtonProps) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [dealName, setDealName] = useState('');
    const [companyName, setCompanyName] = useState(legalName);
    const [aliases, setAliases] = useState<string[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [savingError, setSavingError] = useState<string | null>(null);
    const promoteToDealMutation = usePromoteToDealMutation(orgId);
    const [includeWebsite, setIncludeWebsite] = useState(true);

    const [dealId, setDealId] = useState<string | null>(null);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);

    useEffect(() => {
        if (!showModal) {
            setSavingError(null);
        }
    }, [showModal]);

    useEffect(() => {
        if (legalName) {
            setCompanyName(legalName);
        }
    }, [legalName]);

    useEffect(() => {
        if (uploadComplete) {
            setUploadComplete(false);
            navigate(`/orgs/${orgId}/deals/${dealId}`);
        }
    }, [uploadComplete]);

    useEffect(() => {
        if (dealId && (!files || files.length === 0)) {
            setUploadComplete(true);
        }
    }, [dealId]);

    const handleSave = () => {
        setIsSaving(true);
        console.log("Saving deal with name:", dealName, "company name:", companyName, "aliases:", aliases);

        promoteToDealMutation.mutate({
            dealName: dealName,
            companyName: companyName || '',
            countryCode: countryCode,
            companyAliases: aliases,
            website: includeWebsite ? website : null,
            companyId: companyId,
            registryFiles: registryDocuments.map(doc => { return { id: doc.documentId, registryIdentifier: doc.registryIdentifier } }),
        }, {
            onSuccess: (data) => {
                setDealId(data.dealId);
            },
            onError: () => {
                setIsSaving(false);
                setSavingError("Could not save deal");
            },
        });
    };

    return (
        <>
            <button
                type="button"
                onClick={() => setShowModal(true)} // Show the modal on button click
                className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            >
                Save as Deal
            </button>

            {/* Modal */}
            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75" />
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div className='flex flex-col'>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Enter Deal Name
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={dealName}
                                        onChange={(e) => setDealName(e.target.value)}
                                        placeholder="Deal name"
                                        className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 mt-2 text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                                    Company Name
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        placeholder="Company name"
                                        className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 mt-2 text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                                    Company Aliases
                                </h3>
                                <div className="mt-2">
                                    {aliases.map((alias, index) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <input
                                                type="text"
                                                value={alias}
                                                onChange={(e) => {
                                                    const newAliases = [...aliases];
                                                    newAliases[index] = e.target.value;
                                                    setAliases(newAliases);
                                                }}
                                                placeholder={`Alias ${index + 1}`}
                                                className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const newAliases = [...aliases];
                                                    newAliases.splice(index, 1);
                                                    setAliases(newAliases);
                                                }}
                                                className="ml-2 text-red-500 hover:text-red-700"
                                            >
                                                {/* Bin Icon */}
                                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => setAliases([...aliases, ''])}
                                        className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        + Add Alias
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={includeWebsite}
                                            onChange={(e) => setIncludeWebsite(e.target.checked)}
                                            className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Include website</span>
                                    </label>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleSave}
                                    disabled={!dealName || isSaving} // Disable the button if no deal name is provided or during the save
                                    className="inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                                >
                                    {isSaving ? 'Saving...' : 'Save'} {/* Show "Saving..." during the delay */}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)} // Close modal on cancel
                                    disabled={isSaving} // Disable cancel button during saving
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm disabled:opacity-50"
                                >
                                    Cancel
                                </button>
                            </div>
                            {savingError && <div className="mt-2 text-red-500 text-right text-sm">{savingError}</div>}
                        </div>
                    </div>
                </div>
            )}
            {dealId && files && files.length > 0 && <UploadFilesPixel orgId={orgId} dealId={dealId} files={files} onUploadComplete={() => setUploadComplete(true)} />}
        </>
    );
};

export default CreateButton;
