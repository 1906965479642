import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DragItemData, DropZoneData } from "./types";
import { TableAggregationGroup } from "../../../../../api/tableAggregationLayouts";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const SCALE_STYLES = [
  "scale-[10%]",
  "scale-[20%]",
  "scale-[30%]",
  "scale-[40%]",
  "scale-[50%]",
  "scale-[60%]",
  "scale-[70%]",
  "scale-[80%]",
  "scale-[90%]",
  "scale-[100%]",
] as const;

type ScaleContextType = {
  scaleStyle: (typeof SCALE_STYLES)[number];
  scalePercentage: number;
  incrementScale: () => void;
  decrementScale: () => void;
};

const ScaleContext = createContext<ScaleContextType | undefined>(undefined);

type ScaleProviderProps = {
  children: ReactNode;
};

export const ScaleProvider: React.FC<ScaleProviderProps> = ({ children }) => {
  const [scaleIndex, setScaleIndex] = useState(9); // Default to 100%

  const decrementScale = () => {
    setScaleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const incrementScale = () => {
    setScaleIndex((prevIndex) => Math.min(prevIndex + 1, 9));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "1") {
        decrementScale();
      }
      if (event.key === "2") {
        incrementScale();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const value = {
    scaleStyle: SCALE_STYLES[scaleIndex],
    scalePercentage: (scaleIndex + 1) * 10,
    incrementScale,
    decrementScale,
  };

  return (
    <ScaleContext.Provider value={value}>{children}</ScaleContext.Provider>
  );
};

export const useScale = (): ScaleContextType => {
  const context = useContext(ScaleContext);
  if (!context) {
    throw new Error("useScale must be used within a ScaleProvider");
  }
  return context;
};

type ItemState =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement }
  | { type: "dragging" };

export function useDragItem(dragItemData: DragItemData) {
  const ref = useRef(null);
  const [itemState, setItemState] = useState<ItemState>({ type: "idle" });
  useEffect(() => {
    const el = ref.current;
    invariant(el);
    return draggable({
      element: el,
      getInitialData: () => ({ dragItemData }),
      onDrop: () => setItemState({ type: "idle" }),
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          render({ container }) {
            setItemState({ type: "preview", container });
            return () => setItemState({ type: "dragging" });
          },
        });
      },
    });
  }, []);
  return { ref, itemState };
}

export function useDropZone(
  dropZoneData: DropZoneData,
  allowedTypes?: string[]
) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  useEffect(() => {
    const el = ref.current;
    invariant(el);
    return dropTargetForElements({
      element: el,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({ dropZoneData }),
      canDrop: ({ source }) => {
        if (allowedTypes === undefined) {
          return true;
        }
        const dragItemData = source.data.dragItemData as DragItemData;
        return allowedTypes.includes(dragItemData.type);
      },
    });
  }, []);
  return { ref, isDraggedOver };
}

export function removeEmptyColumnsAndGroups(layout: TableAggregationGroup[]) {
  // iterate over each group and column and remove any empty groups or columns.
  for (let groupIndex = layout.length - 1; groupIndex >= 0; groupIndex--) {
    for (
      let columnIndex = layout[groupIndex].columns.length - 1;
      columnIndex >= 0;
      columnIndex--
    ) {
      if (layout[groupIndex].columns[columnIndex].tables.length === 0) {
        console.log("removing empty column");
        layout[groupIndex].columns.splice(columnIndex, 1);
      }
    }
    if (layout[groupIndex].columns.length === 0) {
      console.log("removing empty group");
      layout.splice(groupIndex, 1);
    }
  }
  return layout;
}
