import { APIError } from "../api/shared";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../types";

// We don't want to retry APIErrors
export const defaultRetryFunc = (failureCount: number, error: Error) =>
  !(error instanceof APIError) && failureCount < 3;

export const useAuth = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  if (user) {
    return { user: new User(user.email || "", user.email_verified || false, getAccessTokenSilently) };
  } else {
    return { user: null };
  }
};
