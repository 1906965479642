import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { CompanyNameSuggestion } from '../api/listCompanyNameSuggestions';
import { getName } from 'country-list';
import {
    useQuickCompanyNamesSuggestionsQuery,
    useExtendedCompanyNamesSuggestionsQuery
} from '../hooks/companyNamesSuggestions';
import { useDebounce } from 'use-debounce';

const Suggestions = ({ orgId, query, handleSuggestionSelected }: {
    orgId: string,
    query: string,
    handleSuggestionSelected: (suggestion: { companyName: string, countryCode: string, id: string }) => void,
}) => {
    const quickSuggestions = useQuickCompanyNamesSuggestionsQuery(orgId, query);
    const extendedSuggestions = useExtendedCompanyNamesSuggestionsQuery(orgId, query);
    const [allSuggestions, setAllSuggestions] = useState<CompanyNameSuggestion[]>([]);

    useEffect(() => {
        const allSuggestions = new Map();

        if (quickSuggestions.data) {
            quickSuggestions.data.forEach((suggestion) => {
                const key = `${suggestion.companyName}-${suggestion.countryCode}`;
                allSuggestions.set(key, suggestion);
            });
        }

        if (extendedSuggestions.data) {
            extendedSuggestions.data.forEach((suggestion) => {
                const key = `${suggestion.companyName}-${suggestion.countryCode}`;
                allSuggestions.set(key, suggestion);
            });
        }

        setAllSuggestions(Array.from(allSuggestions.values()));
    }, [quickSuggestions.data, extendedSuggestions.data]);

    const handleSuggestionSelectedInternal = (suggestion: CompanyNameSuggestion) => {
        setAllSuggestions([]);
        handleSuggestionSelected(suggestion);
    }

    if ((quickSuggestions.isLoading || extendedSuggestions.isLoading) && allSuggestions.length === 0) {
        return <div className="top-full left-0 mt-1 p-2 bg-white border border-gray-300 shadow-lg z-10 text-sm text-gray-500">Loading suggestions...</div>;
    }

    if ((quickSuggestions.isError || extendedSuggestions.isError) && allSuggestions.length === 0) {
        return <div className="top-full left-0 mt-1 p-2 text-sm text-red-500">Failed to fetch suggestions</div>;
    }

    if (allSuggestions.length === 0) {
        return <div className="top-full left-0 mt-1 p-2 bg-white border border-gray-300 shadow-lg z-10 text-sm text-gray-500">No suggestions found</div>;
    }

    return (
        <div>
            {allSuggestions.length > 0 && (
                <ul className="flex flex-col top-full left-0 bg-white border border-gray-300 shadow-lg z-50">
                    {allSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className="suggestion-item flex flex-row p-2 hover:bg-blue-100 cursor-pointer items-baseline"
                            onMouseDown={() => {
                                handleSuggestionSelectedInternal(suggestion);
                            }}
                        >
                            <div className="flex text-black">{suggestion.companyName}</div>
                            <div className="flex text-gray-500 text-sm ml-2 capitalize">
                                {getName(suggestion.countryCode) || suggestion.countryCode}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

interface SearchBarProps {
    companySelected: (company: { companyName: string, countryCode: string, id: string } | null) => void;
}

const SearchBar = ({ companySelected }: SearchBarProps) => {
    const { orgId } = useParams();
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebounce(query, 300);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [selectedSuggestion, setSelectedSuggestion] = useState<{ companyName: string, countryCode: string, id: string } | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    if (!orgId) {
        throw new Error("orgId is required");
    }

    const handleSuggestionSelected = (suggestion: { companyName: string, countryCode: string, id: string }) => {
        setShowSuggestions(false);
        setQuery('');
        setSelectedSuggestion({
            companyName: suggestion.companyName,
            countryCode: suggestion.countryCode,
            id: suggestion.id,
        });
    }

    const handleOnBlur = () => {
        setShowSuggestions(false);
        companySelected(selectedSuggestion);
    }


    const handleSearchBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setShowSuggestions(e.target.value.length > 0);
    }

    return (
        <div className="relative content-center flex flex-col justify-center mt-7 mx-auto w-2/5">
            <input
                type="text"
                ref={inputRef}
                className="flex border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter a company name to search"
                value={query}
                onChange={handleSearchBarChange}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    console.log("onBlur: ", e);
                    handleOnBlur();
                }}
                onFocus={() => setShowSuggestions(true)}
            />
            {
                debouncedQuery && showSuggestions && (
                    <div className="absolute overflow-visible left-0 top-full w-full bg-white shadow-lg z-50 mt-0.5">
                        <Suggestions
                            orgId={orgId}
                            query={debouncedQuery}
                            handleSuggestionSelected={handleSuggestionSelected}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default SearchBar
