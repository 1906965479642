import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface WebsiteRead {
  id: string;
  org_id: string;
  deal_id: string;
  url: string;
  items_ingested: number;
  ingestion_status: "pending" | "completed" | "failed";
  created_at: string;
}

export const listWebsites = async (
  user: User,
  orgId: string,
  dealId: string
): Promise<WebsiteRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/websites`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export const readWebsite = async (
  user: User,
  orgId: string,
  dealId: string,
  websiteId: string
): Promise<WebsiteRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/websites/${websiteId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface WebsiteCreate {
  url: string;
}

export const createWebsite = async (
  user: User,
  orgId: string,
  dealId: string,
  data: WebsiteCreate
): Promise<WebsiteRead> => {
  const response = await resolveFetch<WebsiteRead>(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/websites`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
  return response;
};

export const deleteWebsite = async (
  user: User,
  orgId: string,
  dealId: string,
  websiteId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/websites/${websiteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};
