import { CompanyProfileTemplateRead } from "../../api/companyProfileTemplates";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";

interface Props {
  orgId: string;
  companyProfileTemplate: CompanyProfileTemplateRead;
}

export default function UpdateCompanyProfileTemplateButton({
  orgId,
  companyProfileTemplate,
}: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "company_profile_templates.update")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <button
          type="button"
          onClick={() =>
            navigate(
              `/orgs/${orgId}/company-profile-templates-update/${companyProfileTemplate.id}`
            )
          }
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
        >
          Update
        </button>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Update
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Update
        </button>
      );
  }
}
