import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createBundesanzeigerSearchJob,
  deleteBundesanzeigerSearchJob,
  listBundesanzeigerSearchJobs,
  readBundesanzeigerSearchJob,
  BundesanzeigerSearchJobCreate,
} from "../api/bundesanzeigerSearchJobs";

export const useBundesanzeigerSearchJobsQuery = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "bundesanzeiger-search-jobs"],
    queryFn: () => listBundesanzeigerSearchJobs(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((job) => ["pending", "in_progress"].includes(job.status))) {
        return 10000;
      }
      return false;
    },
  });
};

export const useBundesanzeigerSearchJobQuery = (
  orgId: string,
  dealId: string,
  bundesanzeigerSeachJobId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "bundesanzeiger-search-jobs",
      bundesanzeigerSeachJobId,
    ],
    queryFn: () =>
      readBundesanzeigerSearchJob(
        user,
        orgId,
        dealId,
        bundesanzeigerSeachJobId
      ),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && ["pending", "in_progress"].includes(data.status)) {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateBundesanzeigerSearchJobMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: BundesanzeigerSearchJobCreate) =>
      createBundesanzeigerSearchJob(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "bundesanzeiger-search-jobs"],
      });
    },
  });
};

export const useDeleteBundesanzeigerSearchJobMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      bundesanzeigerSearchJobId,
    }: {
      bundesanzeigerSearchJobId: string;
    }) =>
      deleteBundesanzeigerSearchJob(
        user,
        orgId,
        dealId,
        bundesanzeigerSearchJobId
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "bundesanzeiger-search-jobs"],
      });
    },
  });
};
