import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";
import { CitationInstance } from "../messageUtils";


function downloadFromURL(url: string, fileName: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

export interface CompanyProfileList {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  response_status: "pending" | "complete" | "failed";
  created_at: string;
}

export const listCompanyProfiles = async (
  user: User,
  orgId: string,
  dealId: string
): Promise<CompanyProfileList[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/company-profiles`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface CompanyProfilePropertyValue {
  content: string;
}

export interface CompanyProfileProperty {
  name: string;
  data_type: "text" | "number" | "date" | "boolean" | "options";
  values: CompanyProfilePropertyValue[];
  allow_multiple: boolean;
  description: string | null;
  examples: string | null;
  response: string | null;
  citations: CitationInstance[];
}

export interface CompanyProfileReadData {
  properties: CompanyProfileProperty[];
}

export interface CompanyProfileRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  data: CompanyProfileReadData;
  response_status: "pending" | "completed" | "failed";
  created_at: string;
}

export interface CompanyProfileCreate {
  name: string;
  company_profile_template_id: string;
  use_custom_prompt: boolean;
}

export const createCompanyProfile = async (
  user: User,
  orgId: string,
  dealId: string,
  data: CompanyProfileCreate
): Promise<CompanyProfileRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/company-profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const readCompanyProfile = async (
  user: User,
  orgId: string,
  dealId: string,
  companyProfileId: string
): Promise<CompanyProfileRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/company-profiles/${companyProfileId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};

export const deleteCompanyProfile = async (
  user: User,
  orgId: string,
  dealId: string,
  companyProfileId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/company-profiles/${companyProfileId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};

export type CompanyProfileExportFileFormat = "pdf" | "docx";

export interface CompanyProfileExportCreate {
  file_format: CompanyProfileExportFileFormat;
  include_citations: boolean;
}

export interface CompanyProfileExportRead {
  id: string;
  org_id: string;
  deal_id: string;
  company_profile_id: string;
  file_format: CompanyProfileExportFileFormat;
  container: string;
  blob: string;
  download_url: string;
  created_at: string;
}

export const createCompanyProfileExport = async (
  user: User,
  orgId: string,
  dealId: string,
  companyProfileId: string,
  data: CompanyProfileExportCreate
) => {
  const response = await fetch(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/company-profiles/${companyProfileId}/exports`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }
  );
  const companyProfile = await readCompanyProfile(
    user,
    orgId,
    dealId,
    companyProfileId
  );
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    downloadFromURL(
      url,
      (companyProfile.name === null ? "Unnamed" : companyProfile.name) +
      " Profile Export." + data.file_format
    );
  } else {
    throw new Error(`${response.status}`);
  }
};
