import React, { useState, useEffect } from 'react';
import { FunnelIcon } from "@heroicons/react/20/solid";
import { useRegistryDocumentsQuery } from '../hooks/registryDocuments';
import { useParams } from 'react-router-dom';
import { RegistryDocument } from '../api/listRegistryDocuments';

interface RegistryDocumentsComponentProps {
    legalName: string;
    countryCode: string;
    id: string;
    onDocumentListUpdated: (documents: RegistryDocument[]) => void;
}

const RegistryDocumentsComponent = ({ legalName, countryCode, id, onDocumentListUpdated }: RegistryDocumentsComponentProps) => {
    const { orgId } = useParams();

    if (!orgId) {
        throw new Error("orgId is required");
    }

    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [selectedYears, setSelectedYears] = useState<string[]>([]);

    const { data: fetchedDocuments } = useRegistryDocumentsQuery(orgId, legalName, countryCode, id);
    const [documents, setDocuments] = useState<RegistryDocument[]>(fetchedDocuments || []);

    useEffect(() => {
        if (fetchedDocuments) {
            setDocuments(fetchedDocuments);
        }
    }, [fetchedDocuments?.length]);

    useEffect(() => {
        onDocumentListUpdated(filteredDocuments());
    }, [documents, selectedYears]);

    const years = Array.from(new Set(
        documents.flatMap(doc => doc.effectiveDate)
    )).sort((a: string, b: string) => b.localeCompare(a));

    const toggleYearSelection = (year: string) => {
        setSelectedYears(prevSelected =>
            prevSelected.includes(year)
                ? prevSelected.filter(y => y !== year)
                : [...prevSelected, year]
        );
    };

    const filteredDocuments = () => {
        return documents.filter(doc => {
            return selectedYears.length === 0 || selectedYears.includes(doc.effectiveDate);
        });
    }


    return (
        <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full relative">
            <div className="flex flex-row items-center justify-between">
                <div className="font-bold text-lg">Registry Documents</div>
                <FunnelIcon className="h-6 w-6 cursor-pointer text-gray-500 ml-2" onClick={() => setFilterModalVisible(!filterModalVisible)} />
            </div>
            {filterModalVisible && (
                <div className="absolute right-10 top-5 bg-white border rounded-lg p-3 shadow-lg z-10">
                    <div className="text-sm font-bold mb-2">Filter by Year</div>
                    {years.map((year) => (
                        <div key={year} className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                id={year}
                                value={year}
                                checked={selectedYears.includes(year)}
                                onChange={() => toggleYearSelection(year)}
                                className="mr-2"
                            />
                            <label htmlFor={year} className="text-sm text-gray-700">{year}</label>
                        </div>
                    ))}
                </div>
            )}
            <ul className="flex flex-col space-y-2 overflow-y-auto pr-3" style={{ maxHeight: "540px" }}>
                {filteredDocuments().map((doc, i) => (
                    <li key={i} className="flex flex-row items-center py-2 border-b border-gray-300">
                        <div className="text-sm flex-grow">{doc.documentName}</div>
                        <div className="text-xs text-gray-500 ml-2">{doc.effectiveDate}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RegistryDocumentsComponent
