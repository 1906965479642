import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray, Control, UseFormRegister, FieldErrors } from "react-hook-form";
import { useCreateCompanyProfileTemplateMutation } from "../../hooks/companyProfileTemplates";
import { Property, defaultProperty, defaultExample, PropertyDataType } from "../../api/companyProfileTemplates";
import React, { useState, useEffect } from 'react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
  PlusCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import TabLayout from "../TabLayout";

interface CompanyProfileTemplateFormValues {
  name: string
  properties: Property[]
}

function adjustTextareaHeight(textarea: HTMLTextAreaElement) {
  textarea.style.height = 'auto'; // Reset the height
  textarea.style.height = textarea.scrollHeight + 'px'; // Set the height to the scroll height
}

interface NestedExampleFieldProps {
  control: Control<CompanyProfileTemplateFormValues>;
  index: number;
  register: UseFormRegister<CompanyProfileTemplateFormValues>;
  errors: FieldErrors<CompanyProfileTemplateFormValues>;
}

function NestedExampleField({ control, index, register, errors }: NestedExampleFieldProps) {
  const { fields: exampleFields, append: appendExample, remove: removeExample } = useFieldArray({
    control,
    name: `properties.${index}.examples`,
  });

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach(textarea => {
      adjustTextareaHeight(textarea);
      textarea.addEventListener('input', handleInput);
    });

    return () => {
      textareas.forEach(textarea => {
        textarea.removeEventListener('input', handleInput);
      });
    };
  }, [exampleFields]);

  const handleInput = (event: Event) => {
    const target = event.target as HTMLTextAreaElement;
    adjustTextareaHeight(target);
  };

  return (
    <div>
      {exampleFields.map((example, exampleIndex) => (
        <div key={example.id} className="relative mb-2">
          <textarea
            {...register(`properties.${index}.examples.${exampleIndex}.content`, { required: 'Required' })}
            placeholder={'• Elon Musk: 20.5%\n• The Vanguard Group: 7%\n• BlackRock: 5.8%\n• State Street Corp: 3.3%\n• Geode Capital Management: 1.7%'}
            className={`block w-full rounded-md shadow-sm sm:text-sm placeholder-gray-400 placeholder:italic ${errors?.properties?.[index]?.examples?.[exampleIndex]?.content ? 'border-red-500 focus:border-red-500 focus:ring-red-300' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
          />
          {errors?.properties?.[index]?.examples?.[exampleIndex]?.content && (
            <span className="text-red-500 text-xs pt-1">{errors?.properties?.[index]?.examples?.[exampleIndex]?.content?.message}</span>
          )}
          <button
            type="button"
            onClick={() => removeExample(exampleIndex)}
            className={`absolute bottom-2 right-2 p-1 bg-white text-red-400 hover:text-red-500 rounded-full ${errors?.properties?.[index]?.examples?.[exampleIndex]?.content ? 'bottom-8' : 'bottom-2'}`}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </div>
      ))}
      <div className="flex space-x-2 items-center">
        <button
          title="Add example below"
          type="button"
          onClick={() => appendExample(defaultExample)}
          className="inline-flex items-center rounded bg-white pt-1 text-sm font-medium"
        >
          <div className="flex items-center justify-center h-5 w-5 mr-1">
            <PlusCircleIcon className="text-indigo-500" />
          </div>
          <span className="text-indigo-400 underline">Add example</span>
        </button>
      </div>
    </div>
  );
}

export default function CompanyProfileTemplatesCreate() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { control, handleSubmit, reset, register, formState: { errors } } =
    useForm<CompanyProfileTemplateFormValues>({
      defaultValues: { properties: [defaultProperty] },
    });
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "properties",
  });
  const mutation = useCreateCompanyProfileTemplateMutation(orgId as string);

  return (
    <TabLayout items={[{ name: "Data Extraction Templates", href: `/orgs/${orgId}/company-profile-templates` }, { name: "Create", href: null }]}>
      <form
        className="p-4"
        onSubmit={handleSubmit((companyProfileTemplate) => {
          mutation.mutate(
            {
              name: companyProfileTemplate.name,
              properties: companyProfileTemplate.properties
            },
            {
              onSuccess: () => {
                navigate(`/orgs/${orgId}/company-profile-templates`);
                reset();
              },
            }
          );
        })}
      >
        <div className="flex border-b-[1px] border-gray-200 pb-4 w-full">
          <div className="w-full">
            <article className="prose prose-sm whitespace-pre-line w-full max-w-none">
              <p>Create a new Data Extraction Template.</p>
              <ul>
                <li>
                  <strong>Property name:</strong> The name of the property in the output table.
                </li>
                <li>
                  <strong>Description:</strong> A brief description of the property and output format (e.g., list, paragraph, currency).
                </li>
                <li>
                  <strong>Examples:</strong> Examples of the property output, with a length and format that matches the desired result.
                </li>
              </ul>
            </article>
          </div>
        </div>
        <div className="space-y-8 divide-y divide-gray-200 pt-1">
          <div>
            <div className="space-y-4">
              <div className="">
                <div className="mt-1">
                  <label htmlFor="name" className="block font-medium text-gray-700">
                    Data Extraction Template:&nbsp;
                    <span className="inline-flex items-center">
                      {isEditing ? (
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <input
                              id="name"
                              type="text"
                              className="block text-xl bg-transparent border border-none rounded-md focus:outline-none focus:border-none"
                              {...field}
                              value={field.value ?? "Name"}
                              onBlur={() => {
                                setIsEditing(false)
                                control._defaultValues.name = field.value;
                              }}
                              autoFocus
                            />
                          )}
                        />
                      ) : (
                        <span
                          className="text-gray-600 font-normal cursor-pointer"
                          onClick={() => setIsEditing(true)}
                        >
                          {control._defaultValues.name || "Name"}
                        </span>
                      )}
                      <PencilSquareIcon
                        className="h-4 w-4 ml-1 mb-2 cursor-pointer text-indigo-500 group-hover:text-indigo-400"
                        onClick={() => setIsEditing(true)}
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1">
                {fields.map((field, index) => (
                  <div key={field.id} className={index === fields.length - 1 ? "" : "mb-4"}>
                    <div key="property" className="flex flex-row border border-gray-200 rounded-md pt-4 pl-4 pb-4 pr-1">
                      <div key="inputs" className="flex-auto w-90 col-span-14">
                        <div key="inputs-grid" className="grid grid-cols-12 gap-4">
                          <div className="col-span-2">
                            <div className="flex flex-col">
                              <div className="pb-1">
                                <label className="block text-sm font-medium text-gray-600">
                                  Property name
                                </label>
                              </div>
                              <input
                                {...register(`properties.${index}.name`, { required: 'Required' })}
                                type="text"
                                placeholder='Ownership'
                                className={`block rounded-md shadow-sm sm:text-sm placeholder-gray-400 placeholder:italic ${errors?.properties?.[index]?.name ? 'border-red-500 focus:border-red-500 focus:ring-red-300' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                  }`}
                              />
                              {errors?.properties?.[index]?.name && (
                                <span className="text-red-500 text-xs pt-1">{errors?.properties?.[index]?.name?.message}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-span-2 hidden">
                            <label className="block text-sm font-medium text-gray-600 mb-1">Output Type</label>
                            <select
                              {...register(`properties.${index}.data_type`, { required: 'Required' })}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                              {Object.values(PropertyDataType)
                                .filter((dataType) => dataType === PropertyDataType.String)
                                .map((dataType) => (
                                  <option key={dataType} value={dataType}>
                                    {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-span-10">
                            <label className="block text-sm font-medium text-gray-600 mb-1">Description</label>
                            <input
                              {...register(`properties.${index}.description`, { required: 'Required' })}
                              type="text"
                              placeholder="List of shareholder names and percentages"
                              className={`block w-full rounded-md shadow-sm sm:text-sm placeholder-gray-400 placeholder:italic ${errors?.properties?.[index]?.description ? 'border-red-500 focus:border-red-500 focus:ring-red-300' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                }`}
                            />
                            {errors?.properties?.[index]?.description && (
                              <span className="text-red-500 text-xs pt-1">{errors?.properties?.[index]?.description?.message}</span>
                            )}
                          </div>
                        </div>
                        <div className="grid col-span-6 pt-2">
                          <label className="block text-sm font-medium text-gray-600 mb-1">Examples</label>
                          <NestedExampleField control={control} index={index} register={register} errors={errors} />
                        </div>
                      </div>
                      <div key="actions" className="flex flex-col justify-center items-center pl-1">
                        <button
                          title="Move property up"
                          type="button"
                          onClick={() => move(index, index - 1)}
                          className="inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowUpIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Remove property"
                          type="button"
                          onClick={() => remove(index)}
                          className="hover:text-red-500 inline-flex m-2 items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <TrashIcon className="flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Move property down"
                          type="button"
                          onClick={() => move(index, index + 1)}
                          className="inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowDownIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex space-x-2 items-center w-full">
                <button
                  title="Add field below"
                  type="button"
                  onClick={() => append(defaultProperty)}
                  className="inline-flex items-center h-8 rounded bg-white text-sm font-medium text-gray-700"
                >
                  <div className="flex items-center justify-center h-5 w-5 mr-1">
                    <PlusCircleIcon className="text-indigo-500" />
                  </div>
                  <span className="text-indigo-400 underline">Add property</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="flex justify-end">
            <button
              onClick={() => navigate(`/orgs/${orgId}/company-profile-templates`)}
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </TabLayout >
  );
}
