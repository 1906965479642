import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { readDealFilesTableGroups } from "../api/fileTableGroups";

export const useDealFileTableGroupsQuery = (
  orgId: string,
  dealId: string,
  fileIds: string[]
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "files",
      "file-table-groups",
      ...fileIds,
    ],
    queryFn: () => readDealFilesTableGroups(user, orgId, dealId, fileIds),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
