import React, { useState } from 'react';
import { CloudArrowUpIcon } from '@heroicons/react/24/solid';
import { FileEarmarkArrowUp, Trash } from 'react-bootstrap-icons';

interface FileUploadProps {
    onFileUpload: (files: FileList) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onFileUpload }) => {
    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFiles = Array.from(e.dataTransfer.files);
            setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
            onFileUpload(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFiles = Array.from(e.target.files);
            setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            onFileUpload(e.target.files);
        }
    };

    const removeFile = (index: number) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full relative w-full">
            <div className="flex flex-row items-center justify-between">
                <div className="font-bold text-lg">Upload Your Documents</div>
            </div>
            <div
                className={`w-full rounded-lg'
                    } flex flex-col justify-center items-center space-y-2`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragEnter}
                onDrop={handleDrop}
            >
                <div
                    className={`w-full ${files.length > 0 ? 'h-40' : 'h-80'} mt-2 border-2 border-dashed rounded-lg flex flex-col justify-center items-center ${dragging ? 'border-indigo-500' : 'border-gray-300'
                        }`}
                >
                    <CloudArrowUpIcon className="h-12 w-12 text-indigo-500" />
                    <div className="text-gray-500">Drag and drop files here</div>
                    <div className="text-gray-500 mb-2">or</div>
                    <label className="cursor-pointer inline-flex items-center bg-indigo-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-indigo-600">
                        <FileEarmarkArrowUp className="h-5 w-5 mr-2" />
                        Browse files
                        <input
                            type="file"
                            multiple
                            className="hidden"
                            onChange={handleFileSelect}
                        />
                    </label>
                </div>
            </div>

            {files.length > 0 && (
                <div className="w-full space-y-2">
                    {files.map((file, index) => (
                        <div
                            key={index}
                            className="flex justify-between items-center p-2 border rounded-md"
                        >
                            <span className="text-gray-700">{file.name}</span>
                            <Trash
                                className="h-5 w-5 text-red-500 cursor-pointer"
                                onClick={() => removeFile(index)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FileUploadComponent;