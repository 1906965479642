import { useParams } from "react-router-dom";
import OrgUsers from "./OrgUsers";
import OrgInvites from "./OrgInvites";
import TabLayout from "../TabLayout";

type URLParams = {
  orgId: string;
};

export default function UsersTab() {
  const { orgId } = useParams() as URLParams;

  return (
    <TabLayout items={[{name: "Users", href: null}]}>
      <div className="p-4 space-y-4">
        <OrgUsers orgId={orgId} />
        <OrgInvites orgId={orgId} />
      </div>
    </TabLayout>
  );
}
