import { useAuth0 } from "@auth0/auth0-react";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import info from "../info";

const postHogConfig = {
  api_host: info.postHogAPIHost,
  ui_host: info.postHogUIHost,
  autocapture: false,
};

function AnalyticsInner({ children }: { children: React.ReactNode }) {
  const { user } = useAuth0();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      posthog.identify(user.email, {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
      posthog.group("org", info.orgId);
      posthog.onFeatureFlags(() => {
        if (posthog.isFeatureEnabled("search")) {
          console.log("search feature is enabled!");
        }
        if (posthog.isFeatureEnabled("websites")) {
          console.log("websites feature is enabled!");
        }
        if (posthog.isFeatureEnabled("registry")) {
          console.log("registry feature is enabled!");
        }
        if (posthog.isFeatureEnabled("table_aggregation_mode_select")) {
          console.log("table aggregation mode select feature is enabled!");
        }
      });
    } else {
      posthog.reset();
    }
  }, [user, info.orgId]);

  useEffect(() => {
    if (user) {
      posthog.capture("$pageview", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
  }, [location]);

  return <>{children}</>;
}

export default function Analytics({ children }: { children: React.ReactNode }) {
  if (process.env.NODE_ENV === "development") {
    return <>{children}</>;
  }
  return (
    <PostHogProvider apiKey={info.postHogProjectAPIKey} options={postHogConfig}>
      <AnalyticsInner>{children}</AnalyticsInner>
    </PostHogProvider>
  );
}
