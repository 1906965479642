import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Cell {
  kind: "content" | "rowHeader" | "columnHeader" | "stubHead" | "description";
  row_index: number;
  column_index: number;
  content: string;
}

export interface Table {
  row_count: number;
  column_count: number;
  cells: Cell[];
}

export interface FileTableGroupEntry {
  id: string;
  file_id: string;
  file_name: string;
  page_number: number;
  number: number;
  table: Table;
}

export interface FileTableGroupMergeDataCreate {
  mode: "file_table_group_merge";
  table_groups: FileTableGroupEntry[][];
}

export interface MergedTable {
  judgements: string[];
  table: Table;
}

export interface FileTableGroupMergeData {
  mode: "file_table_group_merge";
  table_groups: FileTableGroupEntry[][];
  merged_tables: (MergedTable | null)[] | null;
}

export interface FileTableMergeFile {
  id: string;
  name: string;
}

export interface FileTableMergeDataCreate {
  mode: "file_table_merge";
  files: FileTableMergeFile[];
}

export interface FileTableMergeData {
  mode: "file_table_merge";
  files: FileTableMergeFile[];
  merged_table: Table | null;
  outliers: number[] | null;
}

export interface FileTableGroupMergeCreate {
  name: string;
  data: FileTableGroupMergeDataCreate | FileTableMergeDataCreate;
}

export interface FileTableGroupMergeRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  data: FileTableGroupMergeData | FileTableMergeData;
  status: "pending" | "complete" | "failed";
  created_at: string;
}

export const createFileTableGroupMerge = async (
  user: User,
  orgId: string,
  dealId: string,
  data: FileTableGroupMergeCreate | FileTableMergeDataCreate
): Promise<FileTableGroupMergeRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-group-merges`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const listFileTableGroupMerges = async (
  user: User,
  orgId: string,
  dealId: string
): Promise<FileTableGroupMergeRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-group-merges`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export const readFileTableGroupMerge = async (
  user: User,
  orgId: string,
  dealId: string,
  fileTableGroupMergeId: string
): Promise<FileTableGroupMergeRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${fileTableGroupMergeId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};

export const deleteFiletableGroupMerge = async (
  user: User,
  orgId: string,
  dealId: string,
  fileTableGroupMergeId: string
): Promise<void> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${fileTableGroupMergeId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};
