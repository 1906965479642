import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { Dispatch, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  NavigationItem,
  UserNavigationItem,
  classNames,
  sidebarBgColor,
  sidebarHighlightColor,
} from "../common";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from "@headlessui/react";
import info from "../../../info";
import { useUpload } from "../../UploadProvider";
import { ArrowUpOnSquareStackIcon } from "@heroicons/react/24/outline";
import React from 'react';


interface OpenSidebarUserMenuProps {
  userNavigation: UserNavigationItem[];
}

function OpenSidebarUserMenu({ userNavigation }: OpenSidebarUserMenuProps) {
  const { user } = useAuth0();
  return (
    <Menu>
      <Menu.Button title="Open User Menu">
        <div className="flex flex-row items-center m-4 space-x-3">
          <div className="w-8 h-8 min-h-[2rem] min-w-[2rem] rounded-full bg-indigo-600 text-white flex items-center justify-center">
            {user && user.email && user.email[0].toUpperCase()}
          </div>
          <p className="text-sm truncate">
            {user && user.email && user.email.toLowerCase()}
          </p>
        </div>
      </Menu.Button>
      <Menu.Items className="absolute bottom-16 rounded-md bg-white flex flex-col mx-2 w-[calc(16rem-1rem)] border border-gray-200 shadow-sm px-1 py-2 space-y-2">
        <p className="text-xs truncate p-1">
          {user && user.email && user.email.toLowerCase()}
        </p>
        <hr className="mx-1" />
        {userNavigation.map((item, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <button
                className={classNames(
                  "flex flex-row items-center space-x-3 hover:bg-gray-100 p-1 rounded-md",
                  active ? "bg-gray-100" : ""
                )}
                onClick={() => item.callback()}
              >
                <item.icon className="h-4 w-4 text-gray-500" />
                <p className="text-xs">{item.name}</p>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

function OpenSidebarUploads() {
  const { uploads } = useUpload();
  if (uploads.size === 0) {
    return <></>;
  }
  return (
    <div className="flex flex-col space-y-1 px-3 pb-3">
      <hr className="mb-3" />
      <div className="flex flex-row items-center space-x-3 p-1">
        <ArrowUpOnSquareStackIcon className="h-6 w-6 text-gray-500" />
        <p className="text-sm">Uploads ({uploads.size})</p>
      </div>
      <div className="rounded-md bg-gray-200 p-1 ">
        <div className="overflow-auto max-h-48">
          {Array.from(uploads.keys()).map((key) => {
            const upload = uploads.get(key);
            if (!upload) {
              return <></>;
            }
            return (
              <div key={key} className="flex flex-row flex-grow items-center space-x-3 p-1">
                <p className="text-sm truncate" title={key}>
                  {key}
                </p>
                <span className="flex-grow" />
                <p className="text-sm">
                  {Math.round((upload.loadedBytes * 100) / upload.totalBytes)}%
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface OpenSidebarProps {
  setSidebarOpen: Dispatch<boolean>;
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[];
}

function OpenSidebar({
  setSidebarOpen,
  navigation,
  userNavigation,
}: OpenSidebarProps) {
  const navigate = useNavigate();
  return (
    <div
      className={`${sidebarBgColor} w-64 min-w-[16rem] h-full border-r border-gray-200 flex flex-col fixed`}
    >
      <div className="flex flex-row items-center">
        <button
          className="my-4 ml-4"
          onClick={() => navigate(`/orgs/${info.orgId}`)}
        >
          <img src="/logo-1.svg" className="h-8" />
        </button>
        <button className="ml-auto mr-4" title="Hide Sidebar">
          <ChevronDoubleLeftIcon
            className=" h-6 w-6 text-gray-500"
            onClick={() => setSidebarOpen(false)}
          />
        </button>
      </div>
      <hr className="mx-4" />
      <div className="flex flex-col mt-3 space-y-1 px-3">
        {navigation.map((item, index) => (
          <button
            key={index}
            className={classNames(
              `flex flex-row items-center space-x-3  outline-[1px] outline-gray-200 p-1 rounded-md hover:${sidebarHighlightColor} hover:outline hover:shadow-sm `,
              item.current ? `${sidebarHighlightColor} outline shadow-sm` : ""
            )}
            onClick={() => navigate(item.href)}
            title={item.name}
          >
            <item.icon className="h-6 w-6 text-gray-500" />
            <p className="text-sm">{item.name}</p>
          </button>
        ))}
      </div>
      <div className="flex flex-grow" />
      <OpenSidebarUploads />
      <hr className="mx-4" />
      <OpenSidebarUserMenu userNavigation={userNavigation} />
    </div>
  );
}

interface ClosedSidebarUserMenuProps {
  userNavigation: UserNavigationItem[];
}

function ClosedSidebarUserMenu({ userNavigation }: ClosedSidebarUserMenuProps) {
  const { user } = useAuth0();
  return (
    <Menu>
      <Menu.Button title="Open User Menu">
        <div className="flex flex-row items-center m-4 space-x-3">
          <div className="w-8 h-8 min-h-[2rem] min-w-[2rem] rounded-full bg-indigo-600 text-white flex items-center justify-center">
            {user && user.email && user.email[0].toUpperCase()}
          </div>
        </div>
      </Menu.Button>
      <Menu.Items className="absolute bottom-4 left-16 rounded-md bg-white flex flex-col mx-2 w-[calc(16rem-1rem)] border border-gray-200 shadow-sm px-1 py-2 space-y-2 z-10">
        <p className="text-xs truncate p-1">
          {user && user.email && user.email.toLowerCase()}
        </p>
        <hr className="mx-1" />
        {userNavigation.map((item, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <button
                className={classNames(
                  "flex flex-row items-center space-x-3 hover:bg-gray-200 p-1 rounded-md",
                  active ? "bg-gray-200" : ""
                )}
                onClick={() => item.callback()}
              >
                <item.icon className="h-4 w-4 text-gray-500" />
                <p className="text-xs">{item.name}</p>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

function ClosedSidebarUploads() {
  const { uploads } = useUpload();
  if (uploads.size === 0) {
    return <></>;
  }
  return (
    <div className="flex flex-col space-y-1 px-3 pb-3">
      <hr className="mb-3" />
      <div className="p-1 relative">
        <ArrowUpOnSquareStackIcon className="h-6 w-6 text-gray-500" />
        <p className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 text-xs text-indigo-500">
          {uploads.size}
        </p>
      </div>
    </div>
  );
}

interface ClosedSidebarProps {
  setSidebarOpen: Dispatch<boolean>;
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[];
}

function ClosedSidebar({
  setSidebarOpen,
  navigation,
  userNavigation,
}: ClosedSidebarProps) {
  const navigate = useNavigate();
  return (
    <div
      className={`${sidebarBgColor} w-16 min-w-[4rem] h-full border-r border-gray-200 flex flex-col items-center fixed`}
    >
      <div className="flex flex-col items-center border-b-[1px] pb-4">
        <button
          className="mt-4"
          onClick={() => navigate(`/orgs/${info.orgId}`)}
        >
          <img src="/logo-mark-1.svg" className="h-8" />
        </button>
        <button className="mt-4" title="Show Sidebar">
          <ChevronDoubleRightIcon
            className="h-6 w-6 text-gray-500 "
            onClick={() => setSidebarOpen(true)}
          />
        </button>
      </div>
      <div className="flex flex-col mt-3 space-y-1 px-3">
        {navigation.map((item) => (
          <button
            key={item.name}
            className={classNames(
              `flex flex-row items-center space-x-3 p-1 rounded-md outline-[1px] outline-gray-200 hover:${sidebarHighlightColor} hover:outline hover:shadow-sm`,
              item.current ? `${sidebarHighlightColor} outline shadow-sm` : ""
            )}
            onClick={() => navigate(item.href)}
            title={item.name}
          >
            <item.icon className="h-6 w-6 text-gray-500" />
          </button>
        ))}
      </div>
      <div className="flex flex-grow" />
      <ClosedSidebarUploads />
      <div>
        <hr className="mx-4" />
        <ClosedSidebarUserMenu userNavigation={userNavigation} />
      </div>
    </div>
  );
}

interface WideOrgDashboardLayoutProps {
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[];
}

export default function WideOrgDashboardLayout({
  navigation,
  userNavigation,
}: WideOrgDashboardLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div className="flex flex-row h-screen">
      {sidebarOpen ? (
        <>
          <OpenSidebar
            setSidebarOpen={setSidebarOpen}
            navigation={navigation}
            userNavigation={userNavigation}
          />
          <main className="flex-1 min-w-0 pl-64">
            <Outlet />
          </main>
        </>
      ) : (
        <>
          <ClosedSidebar
            setSidebarOpen={setSidebarOpen}
            navigation={navigation}
            userNavigation={userNavigation}
          />
          <main className="flex-1 min-w-0 pl-16">
            <Outlet />
          </main>
        </>
      )}
    </div>
  );
}
