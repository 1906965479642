import { useState } from "react";
import { isEqual } from 'lodash';
import SearchBar from './SearchBar';
import React from "react";
import { Search } from 'react-bootstrap-icons';

interface CompanyNameProps {
    companyName: string;
    logoDomain: string | null;
    handleSearchButtonClicked: () => void;
}

const CompanyName = ({ companyName, logoDomain, handleSearchButtonClicked }: CompanyNameProps) => {
    return (
        <div className="w-full content-center flex flex-col justify-center mt-7">
            <div className="flex flex-row mx-auto items-center">
                {logoDomain ? <img src={`https://img.logo.dev/${logoDomain}?token=pk_Z5UAqFRyRn-zOfmEUCyzww`} alt="Company Logo" className="h-8 w-8 mr-2" /> : <div className="animate-pulse h-8 w-8 bg-gray-200 rounded" />}
                <div className="text-lg font-bold">{companyName}</div>
                <Search className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700" onClick={handleSearchButtonClicked} />
            </div>
        </div>
    );
};

interface AlternatingSearchBoxAndCompanyNameProps {
    selectedCompany: { companyName: string; countryCode: string; id: string; } | null;
    setSelectedCompany: (company: { companyName: string; countryCode: string; id: string } | null) => void;
    logo: string | null;
}

const AlternatingSearchBoxAndCompanyName = ({ selectedCompany, setSelectedCompany, logo }: AlternatingSearchBoxAndCompanyNameProps) => {
    const [searchBarVisible, setSearchBarVisible] = useState<boolean>(true);
    const [companyNameVisible, setCompanyNameVisible] = useState<boolean>(false);

    const [searchBarAdditionalClasses, setsearchBarAdditionalClasses] = useState<string>('');
    const [companyNameAdditionalClasses, setCompanyNameAdditionalClasses] = useState<string>('');

    const handleSelectedCompany = (company: { companyName: string, countryCode: string, id: string } | null) => {
        console.log("Selected company:", company);
        if (company && !isEqual(company, selectedCompany)) {
            setSelectedCompany(company);
        }
        if (selectedCompany || company) {
            setsearchBarAdditionalClasses('');
            setCompanyNameAdditionalClasses('');
            setSearchBarVisible(false);
            setCompanyNameVisible(true);
        }
    }

    const handleSearchButtonClicked = () => {
        setsearchBarAdditionalClasses('');
        setCompanyNameAdditionalClasses('');
        setSearchBarVisible(true);
        setCompanyNameVisible(false);
    }

    return <div className="relative w-full content-center flex flex-col justify-center mt-7 mb-7 z-50">
        <div
            className={`absolute w-full content-center flex flex-col justify-center ${searchBarVisible ? 'animate-deploy' : 'animate-retract'} ${searchBarAdditionalClasses}`}
            onAnimationEnd={() => { setsearchBarAdditionalClasses(searchBarVisible ? '' : 'hidden'); }}>
            <SearchBar companySelected={handleSelectedCompany} />
        </div>
        <div
            className={`absolute w-full content-center flex flex-col justify-center ${companyNameVisible ? 'animate-deploy' : 'animate-retract'} ${companyNameAdditionalClasses}`}
            onAnimationEnd={() => { setCompanyNameAdditionalClasses(companyNameVisible ? '' : 'hidden'); }}>
            {selectedCompany && (
                <div className='flex flex-row'>
                    <CompanyName companyName={selectedCompany.companyName} logoDomain={logo} handleSearchButtonClicked={handleSearchButtonClicked} />
                </div>
            )}
        </div>
    </div>;
}

export default AlternatingSearchBoxAndCompanyName;