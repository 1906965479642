import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface PermissionRead {
  id: string;
  permission: string;
}

export interface RoleRead {
  id: string;
  role: string;
  description: string;
  permissions: PermissionRead[];
}

export const listRoles = async (
  user: User,
  orgId: string,
  type?: "org" | "deal"
): Promise<RoleRead[]> => {
  const params = type
    ? new URLSearchParams({ type: type })
    : new URLSearchParams();
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/roles?` + params, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};
