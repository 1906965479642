import { defaultRetryFunc, useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  TableAggregationLayoutCreate,
  createTableAggregationLayout,
} from "../api/tableAggregationLayouts";

export const useCreateTableAggregationLayoutQuery = (
  orgId: string,
  dealId: string,
  fileIds: string[]
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "table-aggregation-layouts",
      ...fileIds,
    ],
    queryFn: () =>
      createTableAggregationLayout(user, orgId, dealId, { file_ids: fileIds }),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateTableAggregationLayoutMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: TableAggregationLayoutCreate) =>
      createTableAggregationLayout(user, orgId, dealId, data),
    onSuccess: (tableAggregationLayout) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          dealId,
          "tableAggregationLayouts",
          ...tableAggregationLayout.files.map((file) => file.id),
        ],
      });
    },
  });
};
