import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { readEmployeeSentiment } from "../api/readEmployeeSentiment";

export const useEmployeeSentimentQuery = (
  orgId: string,
  legalName: string,
  companyWebsite: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "employeeSentiment",
      legalName,
      companyWebsite
    ],
    queryFn: () => readEmployeeSentiment(user, orgId, legalName, companyWebsite),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};