import { Dispatch, useState } from "react";
import { useDealFilesQuery } from "../../../hooks";
import { FileRead } from "../../../api/dealFiles";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { SearchDealFilesOptions } from "../../../api/dealChatThreads";
import { useWebsitesQuery } from "../../../hooks/websites";
import { WebsiteRead } from "../../../api/websites";

export default function FileSelect({
  orgId,
  dealId,
  searchDealFilesOptions,
  setSearchDealFilesOptions,
}: {
  orgId: string;
  dealId: string;
  searchDealFilesOptions: SearchDealFilesOptions;
  setSearchDealFilesOptions: Dispatch<SearchDealFilesOptions>;
}) {
  const dealFilesQuery = useDealFilesQuery(orgId, dealId);
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const [query, setQuery] = useState("");

  if (dealFilesQuery.isLoading || websitesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (dealFilesQuery.isError || websitesQuery.isError) {
    return <div>Error loading files.</div>;
  }

  let dealFilesQueryData = [...dealFilesQuery.data];
  let websitesQueryData = [...websitesQuery.data];

  const selected = new Map<string, FileRead | WebsiteRead>();
  searchDealFilesOptions.file_ids?.forEach((fileId) => {
    const file = dealFilesQueryData.find((file) => file.id === fileId);
    if (file) {
      selected.set(file.id, file);
    }
  });
  searchDealFilesOptions.website_ids?.forEach((websiteId) => {
    const website = websitesQueryData.find(
      (website) => website.id === websiteId
    );
    if (website) {
      selected.set(website.id, website);
    }
  });

  dealFilesQueryData = dealFilesQueryData.filter((file) =>
    file.name.toLowerCase().includes(query.toLowerCase())
  );
  websitesQueryData = websitesQueryData.filter((website) =>
    website.url.toLowerCase().includes(query.toLowerCase())
  );

  const handleSelectFile = (file: FileRead) => {
    if (selected.has(file.id) && searchDealFilesOptions.file_ids) {
      let newFileIds = [...searchDealFilesOptions.file_ids];
      newFileIds = newFileIds.filter((fileId) => fileId !== file.id);
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        file_ids: newFileIds.length > 0 ? newFileIds : null,
      });
    } else if (searchDealFilesOptions.file_ids) {
      const newFileIds = [...searchDealFilesOptions.file_ids, file.id];
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        file_ids: newFileIds,
      });
    } else {
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        file_ids: [file.id],
      });
    }
  };

  const handleSelectWebsite = (website: WebsiteRead) => {
    if (selected.has(website.id) && searchDealFilesOptions.website_ids) {
      let newWebsiteIds = [...searchDealFilesOptions.website_ids];
      newWebsiteIds = newWebsiteIds.filter(
        (websiteId) => websiteId !== website.id
      );
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        website_ids: newWebsiteIds.length > 0 ? newWebsiteIds : null,
      });
    } else if (searchDealFilesOptions.website_ids) {
      const newWebsiteIds = [...searchDealFilesOptions.website_ids, website.id];
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        website_ids: newWebsiteIds,
      });
    } else {
      setSearchDealFilesOptions({
        ...searchDealFilesOptions,
        website_ids: [website.id],
      });
    }
  };

  return (
    <div className="rounded-lg bg-gray-200 outline outline-1 outline-gray-400">
      <div className="p-4 max-h-[50vh] w-[30vw] flex flex-col">
        <input
          type="search"
          className="rounded-md w-full h-8 focuse:outline-none focus:ring-1 focus:ring-indigo-600 mb-2"
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="flex-grow overflow-auto pr-4">
          {selected.size > 0 && (
            <div className="rounded px-1 py-2 border border-b-gray-500 space-y-2 overflow-auto">
              {Array.from(selected.values()).map((value) => {
                if ((value as FileRead).name) {
                  return (
                    // vertically center contents
                    <div key={(value as FileRead).id} className="flex flex-row justify-between items-center">
                      <p
                        title={(value as FileRead).name ? (value as FileRead).name : (value as WebsiteRead).url}
                        className="truncate text-sm text-indigo-600"
                      >
                        {(value as FileRead).name ? (value as FileRead).name : (value as WebsiteRead).url}
                      </p>
                      <div>
                        <XMarkIcon
                          className="h-4 w-4 hover:cursor-pointer"
                          onClick={() => handleSelectFile(value as FileRead)}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    // vertically center contents
                    <div key={(value as WebsiteRead).id} className="flex flex-row justify-between items-center">
                      <p
                        title={'url' in value ? value.url : ''}
                        className="truncate text-sm text-indigo-600"
                      >
                        {'url' in value ? value.url : ''}
                      </p>
                      <div>
                        <XMarkIcon
                          className="h-4 w-4 hover:cursor-pointer"
                          onClick={() => handleSelectWebsite(value as WebsiteRead)}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div className="space-y-2 py-2 px-1">
            {dealFilesQueryData.map((file) => {
              if (selected.has(file.id)) {
                return <></>;
              } else {
                return (
                  <div
                    key={file.id}
                    className=" max-w-[40vw] flex flex-row hover:cursor-pointer "
                    onClick={() => handleSelectFile(file)}
                  >
                    <p title={file.name} className="text-sm truncate">
                      {file.name}
                    </p>
                  </div>
                );
              }
            })}
            {websitesQueryData.map((website) => {
              if (selected.has(website.id)) {
                return <></>;
              } else {
                return (
                  <div
                    key={website.id}
                    className=" max-w-[40vw] flex flex-row hover:cursor-pointer "
                    onClick={() => handleSelectWebsite(website)}
                  >
                    <p title={website.url} className="text-sm truncate">
                      {website.url}
                    </p>
                  </div>
                );
              }
            })}
            {dealFilesQueryData.length === 0 &&
              websitesQueryData.length === 0 && (
                <div className=" max-w-[40vw] flex flex-row">
                  <p className="text-sm">
                    No files or websites matching search.
                  </p>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
