import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createCompaniesHouseSearchJob,
  deleteCompaniesHouseSearchJob,
  listCompaniesHouseSearchJobs,
  readCompaniesHouseSearchJob,
  CompaniesHouseSearchJobCreate,
} from "../api/companiesHouseSearchJobs";

export const useCompaniesHouseSearchJobsQuery = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "companies-house-search-jobs"],
    queryFn: () => listCompaniesHouseSearchJobs(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((job) => ["pending", "in_progress"].includes(job.status))) {
        return 10000;
      }
      return false;
    },
  });
};

export const useCompaniesHouseSearchJobQuery = (
  orgId: string,
  dealId: string,
  companiesHouseSeachJobId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "companies-house-search-jobs",
      companiesHouseSeachJobId,
    ],
    queryFn: () =>
      readCompaniesHouseSearchJob(
        user,
        orgId,
        dealId,
        companiesHouseSeachJobId
      ),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && ["pending", "in_progress"].includes(data.status)) {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateCompaniesHouseSearchJobMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: CompaniesHouseSearchJobCreate) =>
      createCompaniesHouseSearchJob(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "companies-house-search-jobs"],
      });
    },
  });
};

export const useDeleteCompaniesHouseSearchJobMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      companiesHouseSearchJobId,
    }: {
      companiesHouseSearchJobId: string;
    }) =>
      deleteCompaniesHouseSearchJob(
        user,
        orgId,
        dealId,
        companiesHouseSearchJobId
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "companies-house-search-jobs"],
      });
    },
  });
};
