import { DocumentIcon } from "@heroicons/react/24/outline";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  toolbarPlugin,
  ToolbarProps,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { useEffect } from "react";
import { useDealFileQuery } from "../../hooks";
import { useWebpageQuery } from "../../hooks/webpages";
import useJumpToPagePlugin from "./useJumpToPagePlugin";

export interface FileViewerParams {
  fileId: string | null;
  pageNumber: number | null;
  websiteId: string | null;
  webpageId: string | null;
  waiting: boolean;
}

interface Props {
  orgId: string;
  dealId: string;
  fileId: string | null;
  pageNumber: number | null;
  websiteId: string | null;
  webpageId: string | null;
  waiting: boolean;
  setFileViewerParams: (params: FileViewerParams) => void;
}

export default function FileViewer({
  orgId,
  dealId,
  fileId,
  pageNumber,
  websiteId,
  webpageId,
  waiting,
  setFileViewerParams,
}: Props) {
  // Related to container size
  if (fileId === null && websiteId === null) {
    return <Unselected />;
  }

  if (fileId !== null) {
    return (
      <SelectedFile
        orgId={orgId}
        dealId={dealId}
        fileId={fileId}
        pageNumber={pageNumber}
        waiting={waiting}
        setFileViewerParams={setFileViewerParams}
      />
    );
  }

  if (websiteId !== null && webpageId !== null) {
    return (
      <SelectedWebpage
        orgId={orgId}
        dealId={dealId}
        websiteId={websiteId}
        webpageId={webpageId}
        waiting={waiting}
        setFileViewerParams={setFileViewerParams}
      />
    );
  }

  throw new Error("Invalid FileViewerParams.");
}

function StillProcessing() {
  return (
    <div className="h-full w-full rounded-md flex items-center justify-center border border-gray-200">
      <div className="flex flex-col">
        <DocumentIcon className="text-gray-200 max-w-32 max-h-32" />
        <p className="text-gray-400">File still processing.</p>
      </div>
    </div>
  );
}

function Unselected() {
  return (
    <div className="h-full w-full rounded-md flex items-center justify-center border border-gray-200">
      <div className="flex flex-col">
        <DocumentIcon className="text-gray-200 max-w-32 max-h-32" />
        <p className="text-gray-400">No reference selected.</p>
      </div>
    </div>
  );
}

interface SelectedFileProps {
  orgId: string;
  dealId: string;
  fileId: string;
  pageNumber: number | null;
  waiting: boolean;
  setFileViewerParams: (params: FileViewerParams) => void;
}

function SelectedFile({
  orgId,
  dealId,
  fileId,
  pageNumber,
  waiting,
  setFileViewerParams,
}: SelectedFileProps) {
  const fileQuery = useDealFileQuery(orgId, dealId, fileId);

  // Setup toolbar
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });
  const renderToolbar = (
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => {
    return (
      <div className="w-full overflow-hidden">
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar,
    sidebarTabs: () => [],
  });
  const jumpToPagePluginInstance = useJumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;

  useEffect(() => {
    if (waiting && pageNumber !== null) {
      jumpToPage(pageNumber - 1);
      setFileViewerParams({
        fileId,
        pageNumber,
        websiteId: null,
        webpageId: null,
        waiting: false,
      });
    }
  }, [pageNumber, waiting]);

  if (fileQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (fileQuery.isError) {
    return <p>Error loading pages.</p>;
  }

  let url = fileQuery.data.download_url;
  if (process.env.NODE_ENV === "development" && fileQuery.data.download_url) {
    url = fileQuery.data.download_url.replace(
      "http://storage",
      "http://localhost"
    );
  }
  if (url === null) {
    return <StillProcessing />;
  }

  return (
    <div className="h-full w-full">
      {/* <div className="h-full w-full overflow-auto"> */}
      <Viewer
        fileUrl={url}
        plugins={[defaultLayoutPluginInstance, jumpToPagePluginInstance]}
        onDocumentLoad={() => pageNumber != null && jumpToPage(pageNumber - 1)}
      />
      {/* </div> */}
    </div>
  );
}

interface SelectedWebpageProps {
  orgId: string;
  dealId: string;
  websiteId: string;
  webpageId: string;
  waiting: boolean;
  setFileViewerParams: (params: FileViewerParams) => void;
}

function SelectedWebpage({
  orgId,
  dealId,
  websiteId,
  webpageId
}: SelectedWebpageProps) {
  const webpageQuery = useWebpageQuery(orgId, dealId, websiteId, webpageId);

  // useEffect(() => {
  //   if (waiting && pageNumber !== null) {
  //     jumpToPage(pageNumber - 1);
  //     setFileViewerParams({ fileId, pageNumber, waiting: false });
  //   }
  // }, [pageNumber, waiting]);

  if (webpageQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (webpageQuery.isError) {
    return <p>Error loading pages.</p>;
  }

  let url = webpageQuery.data.screenshot_download_url;
  if (process.env.NODE_ENV === "development") {
    url = webpageQuery.data.screenshot_download_url.replace(
      "http://storage",
      "http://localhost"
    );
  }

  return (
    <div className="h-full w-full overflow-auto">
      {/* <div className="h-full w-full overflow-auto"> */}
      <img src={url} />
      {/* </div> */}
    </div>
  );
}
