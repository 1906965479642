import { APIError } from "../../../api/shared";
// import DeleteFileButton from "./DeleteFileButton";
import { useFileTableGroupMergesQuery } from "../../../hooks/fileTableGroupMerges";
import { Link, useParams } from "react-router-dom";
import DeleteFileTableGroupMergeButton from "./DeleteFileTableGroupMergeButton";
import { useDealQuery } from "../../../hooks";
import TabLayout from "../../TabLayout";
import CreateFileTableGroupMergeButton from "../CreateFileTableGroupMergeButton";
import DealAppToolbar from "../../DealAppToolbar";
import Table from "../../Table";
import { TableCellsIcon } from "@heroicons/react/24/outline";

type URLParams = {
  orgId: string;
  dealId: string;
};

function StatusTag({ status }: { status: string }) {
  if (status === "complete") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _FileTableGroupMergesList({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const mergesQuery = useFileTableGroupMergesQuery(orgId, dealId);

  // Insufficient permissions
  if (
    mergesQuery.error instanceof APIError &&
    mergesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list table aggregations."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (mergesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing table aggregations.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!mergesQuery.isLoading) {
    data = mergesQuery.data.map((merge) => {
      return [
        <Link
          key={`${merge.id}-link`}
          to={`/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${merge.id}`}
          className="text-indigo-600"
        >
          {merge.name}
        </Link>,
        <time key={`${merge.id}-time`} dateTime={merge.created_at}>
          {new Date(merge.created_at).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </time>,
        <StatusTag key={`${merge.id}-status`} status={merge.status} />,
        <div key={`${merge.id}-actions`} className="flex flex-grow space-x-1 justify-end">
          <DeleteFileTableGroupMergeButton
            orgId={orgId}
            dealId={dealId}
            fileTableGroupMergeId={merge.id}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Created", "Status", ""]}
      data={data}
      noDataIcon={TableCellsIcon}
      noDataMessage={"You don't have any table aggregations yet."}
      noDataButton={
        <CreateFileTableGroupMergeButton orgId={orgId} dealId={dealId} />
      }
      isLoading={mergesQuery.isLoading}
    />
  );
}

export default function FileTableGroupMergesList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Table Aggregations", href: null },
      ]}
      buttons={[
        <CreateFileTableGroupMergeButton key={orgId} orgId={orgId} dealId={dealId} />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Table Aggregations", active: true }]}
        />
        <_FileTableGroupMergesList orgId={orgId} dealId={dealId} />
      </div>
    </TabLayout>
  );
}
