import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export type DealChatThreadExportFileFormat = "pdf" | "docx";

export interface DealChatThreadExportCreate {
  file_format: DealChatThreadExportFileFormat;
  include_citations: boolean;
}

export interface DealChatThreadExportRead {
  id: string;
  org_id: string;
  deal_id: string;
  deal_chat_thread_id: string;
  file_format: DealChatThreadExportFileFormat;
  container: string;
  blob: string;
  download_url: string;
  created_at: string;
}

export const createDealChatThreadExport = async (
  user: User,
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
  data: DealChatThreadExportCreate
): Promise<DealChatThreadExportRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/chat-threads/${dealChatThreadId}/exports`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      }
    )
  );
};
