import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Cell {
  kind: "content" | "rowHeader" | "columnHeader" | "stubHead" | "description";
  row_index: number;
  column_index: number;
  content: string;
}

export interface Table {
  row_count: number;
  column_count: number;
  cells: Cell[];
}

export interface FileTable {
  id: string;
  file_id: string;
  file_name: string;
  page_number: number;
  number: number;
  table: Table;
}

export interface FileTableGroupsRead {
  org_id: string;
  deal_id: string;
  table_groups: FileTable[][];
}

export const readDealFilesTableGroups = async (
  user: User,
  orgId: string,
  dealId: string,
  fileIds: string[]
): Promise<FileTableGroupsRead> => {
  const urlParams = new URLSearchParams(
    fileIds.map((fileId) => ["file_id", fileId])
  );
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-groups` +
        "?" +
        urlParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};
