import { useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  DealQuestionThreadExportCreate,
  createDealQuestionThreadExport,
} from "../api/dealQuestionThreadExports";

export const useCreateDealQuestionThreadExportMutation = (
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealQuestionThreadExportCreate) =>
      createDealQuestionThreadExport(
        user,
        orgId,
        dealId,
        dealQuestionThreadId,
        data
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "question-threads",
          dealQuestionThreadId,
          "exports",
        ],
      });
    },
  });
};
