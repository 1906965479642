import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useCreateDealMutation } from "../../hooks";
import TabLayout from "../TabLayout";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface FormValues {
  name: string;
  companyName: string;
  companyAliases: { value: string }[];
}

export default function DealsCreate() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register } = useForm<FormValues>({
    defaultValues: {
      name: "",
      companyName: "",
      companyAliases: [],
    },
  });
  const mutation = useCreateDealMutation(orgId as string);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyAliases",
  });

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        { name: "Create", href: null },
      ]}
    >
      <div className="p-4">
        <form
          onSubmit={handleSubmit((values) => {
            mutation.mutate(
              {
                name: values.name,
                company_name: values.companyName,
                company_aliases: values.companyAliases.map(
                  (alias) => alias.value
                ),
              },
              {
                onSuccess: (data) => {
                  navigate(`/orgs/${orgId}/deals/${data.id}`);
                  reset();
                },
              }
            );
          })}
        >
          <div className="flex border-b-[1px] border-gray-200 pb-4">
            <p className="text-sm max-w-lg text-gray-700">Create a new deal.</p>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Deal Name
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="name"
                          type="text"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          {...field}
                          value={field.value ?? ""}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Name
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="companyName"
                          type="text"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          {...field}
                          value={field.value ?? ""}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Aliases
                  </label>
                  <div className="space-y-2">
                    {fields.map((field, index) => (
                      <div className="mt-1" key={index}>
                        <div className="flex">
                          <input
                            key={field.id}
                            {...register(`companyAliases.${index}.value`)}
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          <button
                            title="Remove question"
                            type="button"
                            onClick={() => remove(index)}
                            className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                          >
                            <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      append({ value: "" });
                    }}
                    type="button"
                    className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="py-6">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(`/orgs/${orgId}/deals`)}
                type="button"
                className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </TabLayout>
  );
}
