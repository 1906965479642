import { User } from "../types";
import { baseURL } from "./shared";
import { readFileTableGroupMerge } from "./fileTableGroupMerges";

export interface FileTableGroupMergeExportCreate {
  table_numbers: number[] | null;
}

function downloadFromURL(url: string, fileName: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

export const createFileTableGroupMergeExport = async (
  user: User,
  orgId: string,
  dealId: string,
  fileTableGroupMergeId: string,
  data: FileTableGroupMergeExportCreate
) => {
  const response = await fetch(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${fileTableGroupMergeId}/exports`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }
  );
  const merge = await readFileTableGroupMerge(
    user,
    orgId,
    dealId,
    fileTableGroupMergeId
  );
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    downloadFromURL(url, merge.name + " Tables Export" + ".xlsx");
  } else {
    throw new Error(`${response.status}`);
  }
};
