import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface CompanyOverview {
  description: string;
  publiclyTraded: boolean;
  foundationYear: string;
  headquarters: string;
  employees: string;
}

export const readCompanyOverview = async (
  user: User,
  orgId: string,
  legalName: string,
  companyWebsite: string,
): Promise<CompanyOverview> => {
  return await resolveFetch<CompanyOverview>(
    fetch(`${baseURL}/orgs/${orgId}/companies-search/company-overview?legal_name=${encodeURIComponent(legalName)}&company_website=${encodeURIComponent(companyWebsite)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};