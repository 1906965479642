import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { listRegistryDocuments } from "../api/listRegistryDocuments";

export const useRegistryDocumentsQuery = (
  orgId: string,
  legalName: string,
  countryCode: string,
  id: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "registryDocuments",
      legalName,
      countryCode,
      id
    ],
    queryFn: () => listRegistryDocuments(user, orgId, legalName, countryCode, id),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};