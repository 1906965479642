import { Link } from "react-router-dom";

interface AppTabItem {
  name: string;
  href?: string;
  active: boolean;
}

interface AppTabSelectorProps {
  item: AppTabItem;
}

function AppTabSelector(props: AppTabSelectorProps) {
  if (props.item.active) {
    return (
      <div className="flex flex-row h-full w-fit items-end">
        {props.item.href !== undefined ? (
          <Link to={props.item.href}>
            <p className="text-sm font-semibold">
              {props.item.name}
            </p>
          </Link>
        ) : (
          <p className="text-sm font-semibold">
            {props.item.name}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex flex-row h-full w-fit items-end">
        {props.item.href !== undefined ? (
          <Link to={props.item.href}>
            <p className="text-sm text-gray-500 font-semibold">
              {props.item.name}
            </p>
          </Link>
        ) : (
          <p className="text-sm text-gray-500 font-semibold">
            {props.item.name}
          </p>
        )}
      </div>
    );
  }
}

interface Props {
  orgId?: string;
  dealId?: string;
  tabItems: AppTabItem[];
  // buttonItems: AppButtonItem[];
  children?: React.ReactNode;
}

export default function DealAppToolbar(props: Props) {
  return (
    <div className="flex flex-row overflow-auto">
      <div className="flex flex-row flex-grow border-b-[1px] border-gray-200 space-x-4 items-center pb-2">
        <div className="flex flex-row flex-grow space-x-4 h-full">
          {props.tabItems.map((item, i) => (
            <AppTabSelector key={i} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
