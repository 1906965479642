import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface FileMetadata {
  version: "0.0.0";
  file_name: string;
  page_number: number;
  polygons: Array<[number, Array<number>]>;
  text: string;
}

export interface FileNodeRead {
  id: string;
  file_id: string;
  metadata: FileMetadata;
  text: string | null;
  embedding: number[] | null;
  similarity: number | null;
}

export interface WebpageMetadata {
  version: "0.0.0";
  url: string;
  loaded_at: string;
  text: string;
  file_name: string;
  page_number: number;
}

export interface WebpageNodeRead {
  id: string;
  website_id: string;
  webpage_id: string;
  metadata: WebpageMetadata;
  text: string | null;
  embedding: number[] | null;
  similarity: number | null;
}

export type NodeRead = FileNodeRead | WebpageNodeRead;

function encodeQueryParams(params: { [key: string]: string }) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
}

export const listDealNodes = async (
  user: User,
  orgId: string,
  dealId: string,
  query: string,
  limit: number
): Promise<NodeRead[]> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/nodes?${encodeQueryParams({
        query: query,
        limit: limit.toString(),
      })}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};
