import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import TabLayout from "../TabLayout";

import { CompanyUrls } from './api/readCompanyUrls';

import CompanyOverviewComponent from './components/CompanyOverview';
import CompanySentimentComponent from './components/CompanySentiment';
import CompanyFinancialsComponent from './components/CompanyFinancials';
import RegistryDocumentsComponent from './components/RegistryDocuments';
import FileUploadComponent from './components/UploadDocuments';
import CompanyUrlsComponent from './components/CompanyUrls';
import CreateButton from './components/SaveAsDeal';
import AlternatingSearchBoxAndCompanyName from './components/AlternatingSearchBoxAndCompanyName';
import { RegistryDocument } from './api/listRegistryDocuments';
import background from './background.jpg';

const CompanySearchView = () => {
    const { orgId } = useParams();
    const [selectedCompany, setSelectedCompany] = useState<{ companyName: string, countryCode: string, id: string } | null>(null);
    const [logoDomain, setLogoDomain] = useState<string | null>(null);

    const [companyUrls, setCompanyUrls] = useState<CompanyUrls | null>(null);
    const [searchAccepted, setSearchAccepted] = useState<boolean>(false);
    const [files, setFiles] = useState<FileList | null>(null);
    const [registryDocuments, setRegistryDocuments] = useState<RegistryDocument[]>([]);

    useEffect(() => {
        if (companyUrls?.companyWebsite && companyUrls?.companyWebsite !== '') {
            setLogoDomain(new URL(companyUrls?.companyWebsite || '').hostname);
        }
    }, [companyUrls?.companyWebsite]);

    return (
        <TabLayout
            items={[{ name: "Search Company", href: null }]}
            buttons={[orgId && selectedCompany ? <CreateButton orgId={orgId} companyId={selectedCompany?.id} countryCode={selectedCompany.countryCode} files={files} registryDocuments={registryDocuments} legalName={selectedCompany?.companyName} website={companyUrls?.companyWebsite || ''} /> : null]}
        >
            <div className='w-full content-center flex flex-col'>
                <AlternatingSearchBoxAndCompanyName selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} logo={logoDomain} />
                {selectedCompany && (
                    <CompanyUrlsComponent legalName={selectedCompany.companyName} id={selectedCompany.id} approveDownload={() => setSearchAccepted(true)} updateCompanyUrls={(urls: CompanyUrls) => setCompanyUrls(urls)} />
                )}
                {!selectedCompany && (
                    <div className="w-full h-full content-center flex flex-col justify-center mt-20">
                        <div
                            className="w-1/2 h-96 bg-no-repeat bg-center bg-cover opacity-10 mx-auto"
                            style={{ backgroundImage: `url(${background})` }}
                        />
                    </div>
                )}
            </div>

            <div className="w-full h-full content-start grid grid-cols-12 px-4 pb-4 gap-2 auto-rows-max self-start">
                {searchAccepted && (
                    <div className="col-span-4">
                        <CompanyOverviewComponent legalName={selectedCompany?.companyName || ''} companyWebsite={companyUrls?.companyWebsite || ''} />
                    </div>)}
                {searchAccepted && selectedCompany?.id && (
                    <div className="col-span-4">
                        <CompanyFinancialsComponent id={selectedCompany?.id} />
                    </div>
                )}
                {searchAccepted && (
                    <div className="col-span-4">
                        <CompanySentimentComponent legalName={selectedCompany?.companyName || ''} companyWebsite={companyUrls?.companyWebsite || ''} />
                    </div>
                )}
                {searchAccepted && (
                    <div className="col-span-6">
                        <RegistryDocumentsComponent id={selectedCompany?.id || ''} legalName={selectedCompany?.companyName || ''} countryCode={selectedCompany?.countryCode || ''} onDocumentListUpdated={setRegistryDocuments} />
                    </div>
                )}
                {searchAccepted && (
                    <div className="col-span-6">
                        <FileUploadComponent onFileUpload={setFiles} />
                    </div>
                )}
            </div>
        </TabLayout>
    );
};

export default CompanySearchView


