import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileRead } from "../../../api/dealFiles";
import { Table } from "../../../api/fileTableGroups";
import { APIError } from "../../../api/shared";
import { useDealFilesQuery, useDealQuery } from "../../../hooks";
import { useCreateFileTableGroupMergeMutation } from "../../../hooks/fileTableGroupMerges";
import { useDealFileTableGroupsQuery } from "../../../hooks/fileTableGroups";
import TabLayout from "../../TabLayout";

type URLParams = {
  orgId: string;
  dealId: string;
};

interface TableGroupFormProps {
  orgId: string;
  dealId: string;
  files: FileRead[];
  setSelectedFileIds: (files: string[]) => void;
}

function TableGroupForm({
  orgId,
  dealId,
  files,
  setSelectedFileIds,
}: TableGroupFormProps) {
  const [selectedFiles, setSelectedFiles] = useState<FileRead[]>([]);
  const navigate = useNavigate();

  const selectFile = (id: string) => {
    const file = files.find((file) => file.id === id);
    if (file) {
      setSelectedFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const unselectFile = (id: string) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  // Function to handle file selection
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      return;
    }
    selectFile(e.target.value);
  };

  const handleButtonPress = () => {
    if (selectedFiles.length < 2) {
      alert("Please select at least 2 files to group tables from.");
    } else {
      setSelectedFileIds(selectedFiles.map((file) => file.id));
    }
  };

  const availableFiles = files.filter(
    (file) => !selectedFiles.some((selectedFile) => selectedFile.id === file.id)
  );

  return (
    <div>
      <div className="flex border-b-[1px] border-gray-200 pb-4">
        <p className="text-sm max-w-lg text-gray-700">
          Select files to aggregate tables from.
        </p>
      </div>
      <ul className=" justify-center items-center pt-2">
        {selectedFiles.map((file, index) => (
          <li key={index} className="">
            <div className="flex flex-row items-center pt-2">
              <p className="text-sm text-gray-700">{file.name}</p>
              <button onClick={() => unselectFile(file.id)} className="ml-auto">
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      {availableFiles.length > 0 && (
        <select
          onChange={handleSelectChange}
          className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">Select a file...</option>
          {availableFiles
            .sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((file) => (
              <option key={file.id} value={file.id} id={file.id}>
                {file.name}
              </option>
            ))}
        </select>
      )}

      {/* Submit button if needed */}
      <div className="flex flex-row py-4">
        <button
          onClick={() =>
            navigate(`/orgs/${orgId}/deals/${dealId}/file-table-group-merges`)
          }
          type="button"
          className="ml-auto inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          onClick={handleButtonPress}
          className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Group Tables
        </button>
      </div>
    </div>
  );
}

function TableRender({ table }: { table: Table }) {
  const array = [];
  for (let i = 0; i < table.row_count; i++) {
    const row = [];
    for (let j = 0; j < table.column_count; j++) {
      row.push("");
    }
    array.push(row);
  }

  for (const cell of table.cells) {
    array[cell.row_index][cell.column_index] = cell.content;
  }

  return (
    <div className="outline outline-1 outline-gray-100 rounded-md m-1 prose prose-sm max-w-none">
      <table className="w-full table-auto whitespace-nowrap">
        <tbody>
          {array.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((cell, j) => (
                <td key={j} className="px-2 h-9">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CheckBox({
  state,
  onClick,
}: {
  state: "checked" | "unchecked" | "indeterminate";
  onClick: () => void;
}) {
  if (state === "checked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded items-center justify-center bg-indigo-600 hover:cursor-pointer"
      >
        <CheckIcon className="h-3 w-3 text-white" />
      </div>
    );
  }
  if (state === "unchecked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded border border-1 border-gray-300 items-center justify-center hover:cursor-pointer"
       />
    );
  }
  return (
    <div
      onClick={() => onClick()}
      className="flex h-4 w-4 rounded border border-1 border-gray-300 items-center justify-center hover:cursor-pointer"
    >
      <div className="bg-indigo-600 h-2 w-2 rounded-sm" />
    </div>
  );
}

interface TableGroupsProps {
  orgId: string;
  dealId: string;
  fileIds: string[];
}
function TableGroups({ orgId, dealId, fileIds }: TableGroupsProps) {
  const fileTableGroupsQuery = useDealFileTableGroupsQuery(
    orgId,
    dealId,
    fileIds
  );
  const fileTableGroupsMergeMutation = useCreateFileTableGroupMergeMutation(
    orgId,
    dealId
  );
  const [selectedTableGroups, setSelectedTableGroups] = useState<Set<number>>(
    new Set()
  );
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();
  const [buttonsVisible, setButtonsVisible] = useState(false);

  const checkButtonVisibility = () => {
    const buttons = document.getElementById("bottom-buttons");
    if (!buttons) return;
    const rect = buttons.getBoundingClientRect();
    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    setButtonsVisible(isVisible);
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    const tabBody = document.getElementById("tab-body");
    if (tabBody === null) return;
    tabBody.addEventListener("scroll", checkButtonVisibility);
    // Clean up the event listener when the component unmounts
    return () => tabBody.removeEventListener("scroll", checkButtonVisibility);
  }, []);

  if (fileTableGroupsQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (fileTableGroupsQuery.isError) {
    return <div>Error...</div>;
  }

  const tableGroups = fileTableGroupsQuery.data.table_groups;

  const toggleAllTableGroups = () => {
    if (selectedTableGroups.size > tableGroups.length / 2) {
      setSelectedTableGroups(new Set());
    } else {
      setSelectedTableGroups(
        new Set(tableGroups.map((fileTable, index) => index))
      );
    }
  };

  const toggleTableGroups = (tableNumber: number) => {
    if (selectedTableGroups.has(tableNumber)) {
      const newSelectedTableGroups = new Set(selectedTableGroups);
      newSelectedTableGroups.delete(tableNumber);
      setSelectedTableGroups(newSelectedTableGroups);
    } else {
      const newSelectedTableGroups = new Set(selectedTableGroups);
      newSelectedTableGroups.add(tableNumber);
      setSelectedTableGroups(newSelectedTableGroups);
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center space-x-2 pt-4 pb-4 border-b-[1px] border-gray-200">
        <CheckBox
          state={
            selectedTableGroups.size === tableGroups.length
              ? "checked"
              : selectedTableGroups.size === 0
              ? "unchecked"
              : "indeterminate"
          }
          onClick={toggleAllTableGroups}
        />

        <h1 className="text-md font-semibold">Table Groups</h1>
      </div>
      <div className="space-y-4">
        {fileTableGroupsQuery.data.table_groups.map((tableGroup, index) => (
          <div key={index}>
            <div className="flex flex-row items-center space-x-2 py-2">
              <CheckBox
                state={selectedTableGroups.has(index) ? "checked" : "unchecked"}
                onClick={() => toggleTableGroups(index)}
              />
              <h1 className="font-semibold text-sm">Table Group {index + 1}</h1>
            </div>

            <div
              key={index}
              className="flex flex-row overflow-auto space-x-4 pr-2"
            >
              {tableGroup.map((table, index) => (
                <TableRender key={index} table={table.table} />
              ))}
            </div>
          </div>
        ))}
        <div className="sm:col-span-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Table Aggregation Name
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        {!buttonsVisible && (
          <div
            className="fixed bottom-4 right-0 m-12 p-4 bg-white shadow-md rounded-lg hover:cursor-pointer"
            onClick={() => {
              const tabBody = document.getElementById("tab-body");
              if (tabBody === null) {
                return;
              }
              tabBody.scroll(0, tabBody.scrollHeight);
            }}
          >
            <ArrowDownIcon className="h-6 w-6" />
          </div>
        )}
        <div className="flex flex-row py-4" id="bottom-buttons">
          <button
            onClick={() =>
              navigate(`/orgs/${orgId}/deals/${dealId}/file-table-group-merges`)
            }
            type="button"
            className="ml-auto inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (name === "") {
                alert("Please enter a name for the table merge.");
                return;
              }
              fileTableGroupsMergeMutation.mutate(
                {
                  name: name,
                  data: {
                    mode: "file_table_group_merge",
                    table_groups: fileTableGroupsQuery.data.table_groups.filter(
                      (group, index) => selectedTableGroups.has(index)
                    ),
                  },
                },
                {
                  onSuccess: (data) => {
                    navigate(
                      `/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${data.id}`
                    );
                  },
                }
              );
            }}
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={selectedTableGroups.size === 0}
          >
            Merge Selected
          </button>
        </div>
      </div>
    </div>
  );
}

function V1({
  orgId,
  dealId,
  files,
  selectedFilesIds,
  setSelectedFileIds,
}: {
  orgId: string;
  dealId: string;
  files: FileRead[];
  selectedFilesIds: string[];
  setSelectedFileIds: (files: string[]) => void;
}) {
  return (
    <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
      <TableGroupForm
        orgId={orgId}
        dealId={dealId}
        files={files}
        setSelectedFileIds={setSelectedFileIds}
      />
      {selectedFilesIds.length > 0 && (
        <TableGroups orgId={orgId} dealId={dealId} fileIds={selectedFilesIds} />
      )}
    </div>
  );
}

interface TableFormProps {
  orgId: string;
  dealId: string;
  files: FileRead[];
  setSelectedFileIds: (files: string[]) => void;
}

function TableForm({
  orgId,
  dealId,
  files,
  setSelectedFileIds,
}: TableFormProps) {
  const [selectedFiles, setSelectedFiles] = useState<FileRead[]>([]);
  const [name, setName] = useState<string>("");
  const createFileTableGroupMergeMutation =
    useCreateFileTableGroupMergeMutation(orgId, dealId);
  const navigate = useNavigate();

  const selectFile = (id: string) => {
    const file = files.find((file) => file.id === id);
    if (file) {
      setSelectedFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const unselectFile = (id: string) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  // Function to handle file selection
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      return;
    }
    selectFile(e.target.value);
  };

  const handleButtonPress = () => {
    if (selectedFiles.length < 2) {
      alert("Please select at least 2 files to group tables from.");
    } else if (name === "") {
      alert("Please enter a name for the table aggregation.");
    } else {
      createFileTableGroupMergeMutation.mutate(
        {
          name: name,
          data: {
            mode: "file_table_merge",
            files: selectedFiles.map((file) => ({
              id: file.id,
              name: file.name,
            })),
          },
        },
        {
          onSuccess: (data) => {
            navigate(
              `/orgs/${orgId}/deals/${dealId}/file-table-group-merges/${data.id}`
            );
          },
        }
      );
      setSelectedFileIds(selectedFiles.map((file) => file.id));
    }
  };

  const availableFiles = files.filter(
    (file) => !selectedFiles.some((selectedFile) => selectedFile.id === file.id)
  );

  return (
    <div>
      <div className="flex border-b-[1px] border-gray-200 py-4">
        <p className="text-sm max-w-lg text-gray-700">
          Select files to aggregate tables from.
        </p>
      </div>
      <ul className=" justify-center items-center pt-2">
        {selectedFiles.map((file, index) => (
          <li key={index} className="">
            <div className="flex flex-row items-center pt-2">
              <p className="text-sm text-gray-700">{file.name}</p>
              <button onClick={() => unselectFile(file.id)} className="ml-auto">
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      {availableFiles.length > 0 && (
        <select
          onChange={handleSelectChange}
          className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">Select a file...</option>
          {availableFiles
            .sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((file) => (
              <option key={file.id} value={file.id} id={file.id}>
                {file.name}
              </option>
            ))}
        </select>
      )}
      <div className="sm:col-span-6 mt-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Table Aggregation Name
        </label>
        <div className="mt-1">
          <input
            id="name"
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      {/* Submit button if needed */}
      <div className="flex flex-row py-4">
        <button
          onClick={() =>
            navigate(`/orgs/${orgId}/deals/${dealId}/file-table-group-merges`)
          }
          type="button"
          className="ml-auto inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          onClick={handleButtonPress}
          className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Group Tables
        </button>
      </div>
    </div>
  );
}

function V2({
  orgId,
  dealId,
  files,
  setSelectedFileIds,
}: {
  orgId: string;
  dealId: string;
  files: FileRead[];
  selectedFilesIds: string[];
  setSelectedFileIds: (files: string[]) => void;
}) {
  return (
    <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
      <TableForm
        orgId={orgId}
        dealId={dealId}
        files={files}
        setSelectedFileIds={setSelectedFileIds}
      />
    </div>
  );
}

function _FileTableGroupMergeCreate({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const [selectedFilesIds, setSelectedFileIds] = useState<string[]>([]);
  const [mode, setMode] = useState<
    "file_table_group_merge" | "file_table_merge"
  >("file_table_group_merge");
  const flag = useFeatureFlagEnabled("table_aggregation_mode_select");

  if (filesQuery.isLoading) {
    return (
      <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4" />
    );
  }
  if (
    filesQuery.error instanceof APIError &&
    filesQuery.error.type === "PermissionError"
  ) {
    return (
      <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4" />
    );
  }
  if (filesQuery.isError) {
    return (
      <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4" />
    );
  }

  return (
    <div className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
      {flag === true && (
        <div className="sm:col-span-6 mt-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Table Aggregation Mode
          </label>
          <div className="mt-1">
            <select
              onChange={(e) =>
                setMode(
                  e.target.value as
                    | "file_table_group_merge"
                    | "file_table_merge"
                )
              }
              className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-6"
            >
              <option value="file_table_group_merge">Table Groups</option>
              <option value="file_table_merge">All Tables</option>
            </select>
          </div>
        </div>
      )}

      {mode === "file_table_group_merge" ? (
        <V1
          orgId={orgId}
          dealId={dealId}
          files={filesQuery.data}
          selectedFilesIds={selectedFilesIds}
          setSelectedFileIds={setSelectedFileIds}
        />
      ) : (
        <V2
          orgId={orgId}
          dealId={dealId}
          files={filesQuery.data}
          selectedFilesIds={selectedFilesIds}
          setSelectedFileIds={setSelectedFileIds}
        />
      )}
    </div>
  );
}

export default function FileTableGroupMergeCreate() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Table Aggregations",
          href: `/orgs/${orgId}/deals/${dealId}/file-table-group-merges`,
        },
        { name: "Create", href: null },
      ]}
    >
      <div className="px-4">
        <_FileTableGroupMergeCreate orgId={orgId} dealId={dealId} />
      </div>
    </TabLayout>
  );
}
