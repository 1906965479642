import { useNavigate } from "react-router-dom";

function NavigationIcon(props: { icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element }) {
    return (
        <div className="w-20 h-20 min-w-[5rem] min-h-[5rem] rounded-2xl flex flex-col items-center justify-center bg-gradient-to-br from-indigo-500 to-blue-500 shadow-md">
            <props.icon className="text-white w-12 h-12" />
        </div>
    );
}

function NavigationIconCard(props: {
    name: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    description: string;
    href: string;
}) {
    const navigate = useNavigate();
    return (
        <button className="w-96 mr-4 mb-4" onClick={() => navigate(props.href)}>
            <div className="flex flex-row space-x-4 text-left">
                <NavigationIcon icon={props.icon} />
                <div className="flex-col flex-grow max-w-[18rem]">
                    <h2 className="font-semibold text-sm">{props.name}</h2>
                    <p className="line-clamp-3 text-sm text-gray-600">
                        {props.description}
                    </p>
                </div>
            </div>
        </button>
    );
}

export default NavigationIconCard;
