import { useState } from "react";
import { CompanyProfileTemplateRead } from "../../api/companyProfileTemplates";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { useDeleteCompanyProfileTemplateMutation } from "../../hooks/companyProfileTemplates";
import { hasPermission } from "../../utils";
import DeletionModal from "../DeletionModal";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";

interface Props {
  orgId: string;
  companyProfileTemplate: CompanyProfileTemplateRead;
}

export default function DeleteCompanyProfileTemplateButton({
  orgId,
  companyProfileTemplate,
}: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const deleteCompanyProfileTemplateMutation = useDeleteCompanyProfileTemplateMutation(
    orgId,
    companyProfileTemplate.id
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "company_profile_templates.delete")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <div>
          <DeletionModal
            headingText="Delete Data Extraction Template"
            paragraphText={`Are you sure you want to delete the ${companyProfileTemplate.name} Data Extraction Template?`}
            confirmationButtonText="Delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            mutation={deleteCompanyProfileTemplateMutation}
            mutationData={{}}
            onSuccess={() => navigate(`/orgs/${orgId}/company-profile-templates`)}
          />
          <button
            type="button"
            onClick={() => setOpenDeleteModal(true)}
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Delete
          </button>
        </div>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Delete
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Delete
        </button>
      );
  }
}
