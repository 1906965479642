import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useCreateQuestionTemplateMutation } from "../../hooks/questionTemplates";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import TabLayout from "../TabLayout";

interface QuestionTemplateFormValues {
  name: string;
  questions: { value: string }[];
}

export default function QuestionTemplatesCreate() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register } =
    useForm<QuestionTemplateFormValues>({
      defaultValues: { questions: [{ value: "" }] },
    });
  const { fields, append, remove, insert, move } = useFieldArray({
    control,
    name: "questions",
  });
  const mutation = useCreateQuestionTemplateMutation(orgId as string);

  return (
    <TabLayout items={[{name: "Question Templates", href: `/orgs/${orgId}/question-templates`}, {name: "Create", href: null}]}>
      <form
      className="p-4"
        onSubmit={handleSubmit((values) => {
          mutation.mutate(
            {
              name: values.name,
              questions: values.questions.map((question) => ({
                content: question.value,
              })),
            },
            {
              onSuccess: () => {
                navigate(`/orgs/${orgId}/question-templates`);
                reset();
              },
            }
          );
        })}
      >
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <div className="max-w-lg">
            <article className="prose prose-sm">
              <p>Create a new question template.</p>
              <p>
                In order to make your questions generalize across deals, you can
                use placeholder variables. Placeholder variables are indicated
                by curly braces. They will be replaced by deal specific values
                when the template is used.
              </p>
              <p>
                {`When including a company's name in your question, you should use
                the placeholder variable {"{company}"}. It will be replaced by
                the company name that you provide when setting up a deal. You
                can use it in your questions as follows:`}
              </p>
              <blockquote>{`What was {"{company}"}'s revenue in 2022?`}</blockquote>
            </article>
          </div>
        </div>
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Question Template Name
                </label>
                <div className="mt-1">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="name"
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </div>
              </div>

              {fields.map((field, index) => (
                <div key={field.id} className="sm:col-span-6">
                  <label
                    htmlFor="questions"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Question {index + 1}
                  </label>
                  <div className="mt-1">
                    <div className="flex flex-row">
                      <textarea
                        {...register(`questions.${index}.value`)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm whitespace-pre-wrap"
                      />
                      <div className="flex">
                        <button
                          title="Move question up"
                          type="button"
                          onClick={() => move(index, index - 1)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowUpIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Move question down"
                          type="button"
                          onClick={() => move(index, index + 1)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowDownIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Add question below"
                          type="button"
                          onClick={() => insert(index + 1, { value: "" })}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <PlusIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Remove question"
                          type="button"
                          onClick={() => remove(index)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    append({ value: "" });
                  }}
                  type="button"
                  className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <PlusIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="flex justify-end">
            <button
              onClick={() => navigate(`/orgs/${orgId}/question-templates`)}
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </TabLayout>
  );
}
