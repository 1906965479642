import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { promoteToDeal, PromoteToDealData, PromoteToDealResponse } from "../api/createDealFromCompany";

const usePromoteToDealMutation = (orgId: string) => {
    const { user } = useAuth();
    const queryClient = useQueryClient();
    assertUserIsAuthenticated(user);
    return useMutation({
        mutationFn: (data: PromoteToDealData): Promise<PromoteToDealResponse> => promoteToDeal(user, orgId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
        },
    });
};

export default usePromoteToDealMutation;