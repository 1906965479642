import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import {
  QuestionTemplateCreate,
  createQuestionTemplate,
  updateQuestionTemplate,
  deleteQuestionTemplate,
  listQuestionTemplates,
  readQuestionTemplate,
} from "../api/questionTemplates";

export const useQuestionTemplatesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "question-templates"],
    queryFn: () => listQuestionTemplates(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useQuestionTemplateQuery = (
  orgId: string,
  questionTemplateId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "question-templates", questionTemplateId],
    queryFn: () => readQuestionTemplate(user, orgId, questionTemplateId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateQuestionTemplateMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: QuestionTemplateCreate) =>
      createQuestionTemplate(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "question-templates"],
      });
    },
  });
};

export const useUpdateQuestionTemplateMutation = (
  orgId: string,
  questionTemplateId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: QuestionTemplateCreate) =>
      updateQuestionTemplate(user, orgId, questionTemplateId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "question-templates"],
      });
    },
  });
};

export const useDeleteQuestionTemplateMutation = (
  orgId: string,
  questionTemplateId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: () => deleteQuestionTemplate(user, orgId, questionTemplateId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "question-templates"],
      });
    },
  });
};
