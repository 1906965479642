import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../utils";
import Spinner from "../Spinner";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";

interface Props {
  orgId: string;
  dealId: string;
}

export default function CreateRegistrySearchJobButton({
  orgId,
  dealId,
}: Props) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "document_upload_jobs.create")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <>
          <input className="hidden" type="file" id="file" multiple />
          <button
            type="button"
            onClick={() =>
              navigate(
                `/orgs/${orgId}/deals/${dealId}/registry-search-jobs-create`
              )
            }
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Add
          </button>
        </>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Add
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Add
        </button>
      );
  }
}
