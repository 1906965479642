import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  APIKeyCreate,
  APIKeyUpdate,
  createAPIKey,
  listAPIKeys,
  updateAPIKey,
} from "../api/apiKeys";
import { getBillingStatus } from "../api/billing";
import {
  DealUserChatThreadCreate,
  DealUserChatThreadUpdate,
  createDealUserChatThread,
  deleteDealUserChatThread,
  listDealChatThreads,
  listDealUserChatThreads,
  readDealChatThread,
  readDealUserChatThread,
  updateDealUserChatThread,
} from "../api/dealChatThreads";
import {
  FileCreate,
  createDealFile,
  deleteDealFile,
  listDealFilePages,
  listDealFiles,
  readDealFile,
} from "../api/dealFiles";
import { listDealNodes } from "../api/dealNodes";
import {
  DealQuestionThreadUpdate,
  createDealQuestionThread,
  deleteDealQuestionThread,
  listDealQuestionThreads,
  readDealQuestionThread,
  updateDealQuestionThread
} from "../api/dealQuestionThreads";
import {
  DealCreate,
  DealUpdate,
  createDeal,
  deleteDeal,
  listDeals,
  readDeal,
  updateDeal,
} from "../api/deals";
import {
  OrgInviteCreate,
  OrgInviteUpdate,
  UserInviteUpdate,
  createOrgInvite,
  deleteOrgInvite,
  listOrgInvites,
  listUserInvites,
  updateOrgInvite,
  updateUserInvite,
} from "../api/invites";
import {
  UserOrgCreate,
  createUserOrg,
  listUserOrgs,
  readOrg,
} from "../api/orgs";
import { readQuestionTemplate } from "../api/questionTemplates";
import { listRoles } from "../api/roles";
import {
  SigningKeyCreate,
  SigningKeyUpdate,
  createSigningKey,
  listSigningKeys,
  updateSigningKey,
} from "../api/signingKeys";
import { listUserOrgRoles } from "../api/userOrgRoles";
import {
  OrgUserUpdate,
  deleteOrgUser,
  listOrgUsers,
  readOrgUser,
  updateOrgUser,
} from "../api/users";
import { WebsiteCreate, createWebsite } from "../api/websites";
import { useUpload } from "../components/UploadProvider";
import { assertUserIsAuthenticated } from "../utils";
import { defaultRetryFunc, useAuth } from "./shared";

export const useUserOrgsQuery = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["orgs"],
    queryFn: () => listUserOrgs(user),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateUserOrgMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: UserOrgCreate) => createUserOrg(user, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["orgs"] });
    },
  });
};

export const useAPIKeysQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "api-keys"],
    queryFn: () => listAPIKeys(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateAPIKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: APIKeyCreate) => createAPIKey(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "api-keys"] });
    },
  });
};

export const useUpdateAPIKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      apiKeyId,
      data,
    }: {
      apiKeyId: string;
      data: APIKeyUpdate;
    }) => updateAPIKey(user, orgId, apiKeyId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "api-keys"] });
    },
  });
};

export const useSigningKeysQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "signing-keys"],
    queryFn: () => listSigningKeys(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateSigningKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: SigningKeyCreate) => createSigningKey(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "signing-keys"] });
    },
  });
};

export const useUpdateSigningKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      signingKeyId,
      data,
    }: {
      signingKeyId: string;
      data: SigningKeyUpdate;
    }) => updateSigningKey(user, orgId, signingKeyId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "signing-keys"] });
    },
  });
};

export const useOrgInvitesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "invites"],
    queryFn: () => listOrgInvites(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: OrgInviteCreate) => createOrgInvite(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useUpdateOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      inviteId,
      data,
    }: {
      inviteId: string;
      data: OrgInviteUpdate;
    }) => updateOrgInvite(user, orgId, inviteId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useDeleteOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ inviteId }: { inviteId: string }) =>
      deleteOrgInvite(user, orgId, inviteId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useOrgUsersQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "users"],
    queryFn: () => listOrgUsers(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useOrgUserQuery = (orgId: string, userId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "users", userId],
    queryFn: () => readOrgUser(user, orgId, userId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useSubscriptionStatusQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "billing"],
    queryFn: () => getBillingStatus(user, orgId),
    enabled: !!user,
  });
};

export const useUpdateOrgUserMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ userId, data }: { userId: string; data: OrgUserUpdate }) =>
      updateOrgUser(user, orgId, userId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "users"] });
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useDeleteOrgUserMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      deleteOrgUser(user, orgId, userId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "users"] });
    },
  });
};

export const useUserInvitesQuery = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["invites"],
    queryFn: () => listUserInvites(user),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useUpdateUserInviteMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      inviteId,
      data,
    }: {
      inviteId: string;
      orgId: string;
      data: UserInviteUpdate;
    }) => updateUserInvite(user, inviteId, data),
    onSuccess: (invite, { orgId }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "roles", "permissions"],
      });
      queryClient.invalidateQueries({ queryKey: ["invites"] });
      queryClient.invalidateQueries({ queryKey: ["orgs"] });
    },
  });
};

export const useRolesQuery = (orgId: string, type: "org" | "deal") => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, type === "org" ? "orgRoles" : "dealRoles"],
    queryFn: () => listRoles(user, orgId, type),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useUserOrgPermissionsQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "roles", "permissions"],
    queryFn: async () => {
      const roles = await listUserOrgRoles(user, orgId);
      const permissions = new Set<string>();
      for (const role of roles) {
        for (const permission of role.permissions) {
          permissions.add(permission.permission);
        }
      }
      return permissions;
    },
    enabled: !!user,
    retry: defaultRetryFunc,
    staleTime: 1000 * 60,
  });
};

export const useOrgQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId],
    queryFn: () => readOrg(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useQueryParams = <Type extends Record<string, string>>(
  initParams: Type
): [Type, (arg0: Type) => void] => {
  const [searchParams, setSearchParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(searchParams.entries()) as Type;
  const setQueryParams = (params: Type) => setSearchParams(params);
  return [queryParams, setQueryParams];
};

export const useDealsQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals"],
    queryFn: () => listDeals(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useDealQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId],
    queryFn: () => readDeal(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateDealMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealCreate) => createDeal(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useCreateDealWithWebsiteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: async ({
      dealData,
      websiteData,
    }: {
      dealData: DealCreate;
      websiteData: WebsiteCreate | null;
    }) => {
      const deal = await createDeal(user, orgId, dealData);
      let website = null;
      if (websiteData !== null) {
        website = await createWebsite(user, orgId, deal.id, websiteData);
      }
      return { deal, website };
    },
    onSuccess: ({ deal }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", deal.id, "websites"],
      });
    },
  });
};

export const useUpdateDealMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUpdate) => updateDeal(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals", dealId] });
    },
  });
};

export const useDeleteDealMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ dealId }: { dealId: string }) =>
      deleteDeal(user, orgId, dealId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useDealFilesQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files"],
    queryFn: () => listDealFiles(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((file) => file.ingestion_status === "pending")) {
        return 10000;
      }
      return false;
    },
  });
};

export const useDealFileQuery = (
  orgId: string,
  dealId: string,
  fileId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId],
    queryFn: () => readDealFile(user, orgId, dealId, fileId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.ingestion_status === "pending") {
        return 10000;
      }
      return false;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useDealFilePagesQuery = (
  orgId: string,
  dealId: string,
  fileId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId, "pages"],
    queryFn: () => listDealFilePages(user, orgId, dealId, fileId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateDealFileMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { updateUpload, removeUpload } = useUpload();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: FileCreate) =>
      createDealFile(
        user,
        orgId,
        dealId,
        data,
        () =>
          updateUpload(data.file.name, {
            loadedBytes: 0,
            totalBytes: data.file.size,
          }),
        (progress) =>
          updateUpload(data.file.name, {
            loadedBytes: progress.loadedBytes,
            totalBytes: data.file.size,
          }),
        () => removeUpload(data.file.name)
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files"],
      });
    },
  });
};

export const useDeleteDealFileMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ fileId }: { fileId: string }) =>
      deleteDealFile(user, orgId, dealId, fileId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files"],
      });
    },
  });
};

export const useDealUserChatThreadsQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "user", "chat-threads"],
    queryFn: () => listDealUserChatThreads(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useDealChatThreadsQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "chat-threads"],
    queryFn: () => listDealChatThreads(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useDealUserChatThreadQuery = (
  orgId: string,
  dealId: string,
  dealChatThreadId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "user",
      "chat-threads",
      dealChatThreadId,
    ],
    queryFn: () =>
      readDealUserChatThread(user, orgId, dealId, dealChatThreadId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.response_status === "pending") {
        return 500;
      }
      return false;
    },
  });
};

export const useDealChatThreadQuery = (
  orgId: string,
  dealId: string,
  dealChatThreadId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "chat-threads", dealChatThreadId],
    queryFn: () => readDealChatThread(user, orgId, dealId, dealChatThreadId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.response_status === "pending") {
        return 1000;
      }
      return false;
    },
  });
};

export const useCreateDealUserChatThreadMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUserChatThreadCreate) =>
      createDealUserChatThread(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "user", "chat-threads"],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "chat-threads"],
      });
    },
  });
};

export const useUpdateDealUserChatThreadMutation = (
  orgId: string,
  dealId: string,
  dealChatThreadId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUserChatThreadUpdate) =>
      updateDealUserChatThread(user, orgId, dealId, dealChatThreadId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "user",
          "chat-threads",
          dealChatThreadId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "chat-threads", dealChatThreadId],
      });
    },
  });
};

export const useDeleteDealUserChatThreadMutation = (
  orgId: string,
  dealId: string,
  dealChatThreadId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: () =>
      deleteDealUserChatThread(user, orgId, dealId, dealChatThreadId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "user", "chat-threads"],
      });
    },
  });
};

export const useDealQuestionThreadsQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "question-threads"],
    queryFn: () => listDealQuestionThreads(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useDealQuestionThreadQuery = (
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "question-threads",
      dealQuestionThreadId,
    ],
    queryFn: () =>
      readDealQuestionThread(user, orgId, dealId, dealQuestionThreadId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.response_status === "pending") {
        return 1000;
      }
      return false;
    },
  });
};

interface DealQuestionThreadCreateWithTemplate {
  name: string;
  questionTemplateId: string;
}

export const useCreateDealQuestionThreadWithTemplateMutation = (
  orgId: string,
  dealId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: async (data: DealQuestionThreadCreateWithTemplate) => {
      const questionTemplate = await readQuestionTemplate(
        user,
        orgId,
        data.questionTemplateId
      );
      return createDealQuestionThread(user, orgId, dealId, {
        name: data.name,
        thread: {
          questions_and_messages: questionTemplate.questions.map(
            (question) => ({ question, messages: null, short_answer: null })
          ),
        },
      });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "question-threads"],
      });
    },
  });
};

export const useUpdateDealQuestionThreadMutation = (
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: async (data: DealQuestionThreadUpdate) => {
      return updateDealQuestionThread(
        user,
        orgId,
        dealId,
        dealQuestionThreadId,
        data
      );
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "question-threads",
          dealQuestionThreadId,
        ],
      });
    },
  });
};

export const useDeleteDealQuestionThreadMutation = (
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: () =>
      deleteDealQuestionThread(user, orgId, dealId, dealQuestionThreadId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "question-threads"],
      });
    },
  });
};

export { useAuth };

export const useListDealNodesQuery = (
  orgId: string,
  dealId: string,
  query: string,
  limit: number
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "nodes", query],
    queryFn: () => listDealNodes(user, orgId, dealId, query, limit),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

