import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface CompaniesHouseSearchJobData {
  company_name: string;
  fy_start: number | null;
  fy_end: number | null;
}

export interface CompaniesHouseSearchJobList {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "completed" | "failed";
  type: "companies_house_search";
  data: CompaniesHouseSearchJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const listCompaniesHouseSearchJobs = async (
  user: User,
  orgId: string,
  dealId: string
): Promise<CompaniesHouseSearchJobList[]> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/companies-house-search-jobs`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};

export interface CompaniesHouseSearchJobRead {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "complete" | "failed";
  data: CompaniesHouseSearchJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const readCompaniesHouseSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  companiesHouseSearchJobId: string
): Promise<CompaniesHouseSearchJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/companies-house-search-jobs/${companiesHouseSearchJobId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};

export interface CompaniesHouseSearchJobCreate {
  company_name: string;
  fy_start: number | null;
  fy_end: number | null;
}

export const createCompaniesHouseSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: CompaniesHouseSearchJobCreate
): Promise<CompaniesHouseSearchJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/companies-house-search-jobs`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      }
    )
  );
};

export const deleteCompaniesHouseSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  companiesHouseSearchJobId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/companies-house-search-jobs/${companiesHouseSearchJobId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
  );
};
