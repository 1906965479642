import { useParams } from "react-router-dom";
import { useQuestionTemplateQuery } from "../../hooks/questionTemplates";
import { APIError } from "../../api/shared";
import TabLayout from "../TabLayout";
import UpdateQuestionTemplateButton from "./UpdateQuestionTemplateButton";
import DeleteQuestionTemplateButton from "./DeleteQuestionTemplateButton";
import DealAppToolbar from "../DealAppToolbar";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Table from "../Table";

type URLParams = {
  orgId: string;
  questionTemplateId: string;
};

const QuestionTemplateTab = () => {
  const { orgId, questionTemplateId } = useParams() as URLParams;
  const questionTemplateQuery = useQuestionTemplateQuery(
    orgId,
    questionTemplateId
  );

  // Loading
  if (questionTemplateQuery.isLoading) {
    return (
      <TabLayout items={[{ name: "Question Template", href: null }]}>
        <p className="py-4 text-sm text-gray-500">Loading question template.</p>
      </TabLayout>
    );
  }

  // Insufficient permissions
  if (
    questionTemplateQuery.error instanceof APIError &&
    questionTemplateQuery.error.type === "PermissionError"
  ) {
    return (
      <TabLayout items={[{ name: "Question Template", href: null }]}>
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to view this question template."}
        </p>
      </TabLayout>
    );
  }

  // Error
  if (questionTemplateQuery.isError) {
    return (
      <TabLayout items={[{ name: "Question Template", href: null }]}>
        <p className="py-4 text-sm text-gray-500">
          Error viewing question template.
        </p>
      </TabLayout>
    );
  }

  const data = questionTemplateQuery.data.questions.map((question, index) => {
    return [
      <p key={`index-${index}`}>{index + 1}</p>,
      <p key={`content-${index}`}>{question.content}</p>
    ];
  });

  return (
    <TabLayout
      items={[
        {
          name: "Question Templates",
          href: `/orgs/${orgId}/question-templates`,
        },
        { name: questionTemplateQuery.data.name, href: null },
      ]}
      buttons={[
        <UpdateQuestionTemplateButton
          key="update-button"
          orgId={orgId}
          questionTemplate={questionTemplateQuery.data}
        />,
        <DeleteQuestionTemplateButton
          key="delete-button"
          orgId={orgId}
          questionTemplate={questionTemplateQuery.data}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar tabItems={[{ name: "Questions", active: true }]} />
        <Table
          columnHeaders={["", "Question"]}
          data={data}
          noDataIcon={QuestionMarkCircleIcon}
          noDataMessage="You don't have any questions in your question template yet."
          noDataButton={
            <UpdateQuestionTemplateButton
              orgId={orgId}
              questionTemplate={questionTemplateQuery.data}
            />
          }
        />
      </div>
    </TabLayout>
  );
};

export default QuestionTemplateTab;
