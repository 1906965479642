import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface CompanyUrls {
  companyWebsite: string;
  linkedinUrl: string;
  legalName: string;
  id: string;
}

export const readCompanyUrls = async (
  user: User,
  orgId: string,
  legalName: string,
  id: string,
): Promise<CompanyUrls> => {
  return await resolveFetch<CompanyUrls>(
    fetch(`${baseURL}/orgs/${orgId}/companies-search/company-urls?company_name=${encodeURIComponent(legalName)}&id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};