import { useState } from "react";
import { Fragment } from "react";
import { useUpdateDealUserChatThreadMutation } from "../../../hooks";
import { FileViewerParams } from "../../FileViewer";
import { User, useAuth0 } from "@auth0/auth0-react";
import CustomMarkdown from "../../Markdown";
import {
  ChatMessageThread,
  DealUserChatThreadRead,
  SearchDealFilesOptions,
} from "../../../api/dealChatThreads";
import {
  CitationInstance,
  getMessagesAndCitationInstances,
} from "../../../messageUtils";
import OpenAI from "openai";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import FileSelect from "./FileSelect";

interface MessageProps {
  message: OpenAI.Chat.ChatCompletionMessageParam;
  citationInstances: CitationInstance[];
  user: User | undefined;
  setFileViewerParams: (params: FileViewerParams) => void;
  status: "pending" | "completed" | "failed";
}

const AssistantLoadingMessage: React.FC = () => {
  return (
    <div>
      <div className="flex flex-row space-x-2">
        <span className="w-6 h-6 rounded-full bg-fuchsia-600 text-white flex items-center justify-center">
          C
        </span>
        <h1 className="font-bold text-sm">Capsa AI</h1>
      </div>
      <div className="bg-gray-100 mt2 rounded mb-8 ml-8 prose prose-sm">
        <p>Loading...</p>
      </div>
    </div>
  );
};

const AssistantErrorMessage: React.FC = () => {
  return (
    <div>
      <div className="flex flex-row space-x-2">
        <span className="w-6 h-6 rounded-full bg-fuchsia-600 text-white flex items-center justify-center">
          C
        </span>
        <h1 className="font-bold text-sm">Capsa AI</h1>
      </div>
      <div className="bg-gray-100 mt2 rounded mb-8 ml-8 prose prose-sm">
        <p>Sorry, something went wrong.</p>
      </div>
    </div>
  );
};

const Message: React.FC<MessageProps> = ({
  message,
  citationInstances,
  user,
  setFileViewerParams,
  status,
}) => {
  if (message.role === "user" && typeof message.content === "string") {
    return (
      <div>
        <div className="flex flex-row space-x-2">
          <div className="w-6 h-6 rounded-full bg-indigo-600 text-white flex items-center justify-center">
            {user && user.email && user.email[0].toUpperCase()}
          </div>
          <h1 className="font-bold text-sm">You</h1>
        </div>
        <div className="bg-gray-100 mt2 rounded mb-8 ml-8">
          <CustomMarkdown
            content={message.content}
            citationInstances={citationInstances}
            setFileViewerParams={setFileViewerParams}
            showCursor={false}
          />
        </div>
      </div>
    );
  }
  if (message.role === "assistant" && message.tool_calls) {
    return <></>;
  }
  if (message.role === "tool") {
    return <></>;
  }
  if (message.role === "assistant" && message.content && status !== "failed") {
    return (
      <div>
        <div className="flex flex-row space-x-2">
          <span className="w-6 h-6 rounded-full bg-fuchsia-600 text-white flex items-center justify-center">
            C
          </span>
          <h1 className="font-bold text-sm">Capsa AI</h1>
        </div>
        <div className="bg-gray-100 mt2 rounded mb-8 ml-8">
          <CustomMarkdown
            content={message.content}
            citationInstances={citationInstances}
            setFileViewerParams={setFileViewerParams}
            showCursor={status === "pending"}
          />
        </div>
      </div>
    );
  }
  if (message.role === "assistant" && status === "failed") {
    return <AssistantErrorMessage />;
  }
  throw new Error("Not implemented");
};

interface Props {
  orgId: string;
  dealId: string;
  dealChatThread: DealUserChatThreadRead;
  setFileViewerParams: (params: FileViewerParams) => void;
}

const ChatWindow: React.FC<Props> = ({
  orgId,
  dealId,
  dealChatThread,
  setFileViewerParams,
}) => {
  const { user } = useAuth0();

  const updateDealUserChatThreadMutation = useUpdateDealUserChatThreadMutation(
    orgId,
    dealId,
    dealChatThread.id
  );
  const [input, setInput] = useState("");
  const [searchDealFileOptions, setSearchDealFileOptions] =
    useState<SearchDealFilesOptions>(
      dealChatThread.thread.search_deal_files_options
    );

  const handleSend = () => {
    if (input.trim() && dealChatThread) {
      const newThread: ChatMessageThread = JSON.parse(
        JSON.stringify(dealChatThread.thread)
      );
      newThread.messages.push({
        role: "user",
        content: input,
      });
      newThread.search_deal_files_options = searchDealFileOptions;
      updateDealUserChatThreadMutation.mutate({
        name: null,
        thread: newThread,
      });
      setInput("");
    }
  };

  const thread = dealChatThread.thread;
  const { messagesAndCitationInstances } = getMessagesAndCitationInstances(
    thread.messages,
    null
  );
  const responseStatus = dealChatThread.response_status;
  const previousMessage =
    messagesAndCitationInstances[messagesAndCitationInstances.length - 1];
  return (
    <div className="rounded-lg bg-gray-100 w-full h-full ml-2 flex flex-col outline outline-1 outline-gray-200 relative">
      <div className="absolute top-4 right-4">
        <Popover className="relative">
          <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <div className="float-right text-gray-500 hover:cursor-pointer">
              <FunnelIcon className="h-6 w-6" />
              {searchDealFileOptions.file_ids &&
                searchDealFileOptions.file_ids.length > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 h-3 w-3 bg-indigo-500 outline outline-2 outline-gray-100 rounded-full" />
                )}
              {searchDealFileOptions.website_ids &&
                searchDealFileOptions.website_ids.length > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 h-3 w-3 bg-indigo-500 outline outline-2 outline-gray-100 rounded-full" />
                )}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-full z-10 flex w-screen max-w-max -translate-x-full px-4 pr-2">
              <FileSelect
                orgId={orgId}
                dealId={dealId}
                searchDealFilesOptions={searchDealFileOptions}
                setSearchDealFilesOptions={setSearchDealFileOptions}
              />
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      <div className="flex-1 overflow-auto p-4 mr-4">
        {messagesAndCitationInstances.map(
          ({ message, citationInstances }, index) => (
            <Message
              key={index}
              message={message}
              citationInstances={citationInstances}
              user={user}
              setFileViewerParams={setFileViewerParams}
              status={
                index === messagesAndCitationInstances.length - 1
                  ? responseStatus
                  : "completed"
              }
            />
          )
        )}
        {responseStatus === "pending" &&
          previousMessage.message.role !== "assistant" &&
          previousMessage.message.content && <AssistantLoadingMessage />}
        {responseStatus === "failed" &&
          previousMessage.message.role !== "assistant" &&
          previousMessage.message.content && <AssistantErrorMessage />}
      </div>
      <div className="p-4">
        <div className="flex flex-row space-x-4">
          <textarea
            className="border rounded-lg w-full p-4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <button
            type="button"
            onClick={handleSend}
            className=" inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Send
          </button>
        </div>
        {searchDealFileOptions.file_ids &&
          searchDealFileOptions.file_ids.length > 0 && (
            <div className="flex flex-row items-center pl-1 pt-2 space-x-1">
              <FunnelIcon className="h-3 w-3 text-indigo-600" />
              <p className="text-sm text-indigo-600">File filters active.</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default ChatWindow;
