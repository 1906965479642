import { APIError } from "../../../api/shared";
import { useDealChatThreadQuery, useDealQuery } from "../../../hooks";
import { useParams } from "react-router-dom";
import ChatWindow from "./ChatWindow";
import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import TabLayout from "../../TabLayout";

type URLParams = {
  orgId: string;
  dealId: string;
  dealChatThreadId: string;
};

function _DealChatThread({
  orgId,
  dealId,
  dealChatThreadId,
}: {
  orgId: string;
  dealId: string;
  dealChatThreadId: string;
}) {
  const dealChatThreadQuery = useDealChatThreadQuery(
    orgId,
    dealId,
    dealChatThreadId
  );
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  // Loading
  if (dealChatThreadQuery.isLoading) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">Loading chat thread.</p>
      </ul>
    );
  }

  // Insufficient permissions
  if (
    dealChatThreadQuery.error instanceof APIError &&
    dealChatThreadQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to view this chat thread."}
        </p>
      </ul>
    );
  }

  // Error
  if (dealChatThreadQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          Error viewing this chat thread.
        </p>
      </ul>
    );
  }

  return (
    <div className="flex flex-row h-[calc(100vh-9.5rem)] sm:h-[calc(100vh-5.5rem)]">
      <div className="w-1/2 h-full">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="w-1/2 h-full">
        <ChatWindow
          orgId={orgId}
          dealId={dealId}
          dealChatThread={dealChatThreadQuery.data}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
    </div>
  );
}

export default function DealChatThread() {
  const { orgId, dealId, dealChatThreadId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const dealChatThreadQuery = useDealChatThreadQuery(
    orgId,
    dealId,
    dealChatThreadId
  );

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals/${dealId}` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Chat Threads",
          href: `/orgs/${orgId}/deals/${dealId}/chat-threads-su`,
        },
        { name: dealChatThreadQuery.data?.name || "...", href: null },
      ]}
    >
      <div className="p-4">
        <_DealChatThread
          orgId={orgId}
          dealId={dealId}
          dealChatThreadId={dealChatThreadId}
        />
      </div>
    </TabLayout>
  );
}
