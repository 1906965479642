import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import {
  listCompanyProfileTemplates,
  readCompanyProfileTemplate,
  CompanyProfileTemplateCreate,
  createCompanyProfileTemplate,
  CompanyProfileTemplateUpdate,
  updateCompanyProfileTemplate,
  deleteCompanyProfileTemplate,
} from "../api/companyProfileTemplates";

export const useCompanyProfileTemplatesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "company-profile-templates"],
    queryFn: () => listCompanyProfileTemplates(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCompanyProfileTemplateQuery = (
  orgId: string,
  companyProfileTemplateId: string
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "company-profile-templates", companyProfileTemplateId],
    queryFn: () => readCompanyProfileTemplate(user, orgId, companyProfileTemplateId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateCompanyProfileTemplateMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: CompanyProfileTemplateCreate) =>
      createCompanyProfileTemplate(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "company-profile-templates"],
      });
    },
  });
};

export const useUpdateCompanyProfileTemplateMutation = (
  orgId: string,
  companyProfileTemplateId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: CompanyProfileTemplateUpdate) =>
      updateCompanyProfileTemplate(user, orgId, companyProfileTemplateId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "company-profile-templates"],
      });
    },
  });
};

export const useDeleteCompanyProfileTemplateMutation = (
  orgId: string,
  companyProfileTemplateId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: () => deleteCompanyProfileTemplate(user, orgId, companyProfileTemplateId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "company-profile-templates"],
      });
    },
  });
};