import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Role {
  role: string;
}

export interface OrgUserRead {
  id: string;
  auth_identity_id: string;
  email: string;
  roles: Role[];
}

export const listOrgUsers = async (
  user: User,
  orgId: string
): Promise<OrgUserRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/users`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export const readOrgUser = async (
  user: User,
  orgId: string,
  userId: string
): Promise<OrgUserRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface OrgUserUpdate {
  roles: string[];
}

export const updateOrgUser = async (
  user: User,
  orgId: string,
  userId: string,
  data: OrgUserUpdate
): Promise<OrgUserRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const deleteOrgUser = async (
  user: User,
  orgId: string,
  userId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/users/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};
