import { User } from "../../../types";
import { baseURL, resolveFetch, mapToSnakeCase } from "../../../api/shared";

export interface PromoteToDealResponse {
    dealId: string;
    dealName: string;
    companyName: string;
    companyAliases: string[];
}

interface RegistryFile {
    id: string,
    registryIdentifier: string,
}

export interface PromoteToDealData {
    dealName: string,
    companyName: string,
    companyId: string,
    companyAliases: string[],
    website: string | null,
    registryFiles: RegistryFile[],
    countryCode: string,
}

export const promoteToDeal = async (
    user: User,
    orgId: string,
    data: PromoteToDealData
): Promise<PromoteToDealResponse> => {

    console.log(data);

    const body = mapToSnakeCase<typeof data>(data);
    return await resolveFetch(
        fetch(`${baseURL}/orgs/${orgId}/companies-search`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await user.getIdToken()}`,
                },
                body: JSON.stringify(body),
            }),
        true
    );
};