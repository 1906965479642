import { useRef } from "react";
import { useCreateDealFileMutation } from "../../hooks";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { checkForPassword, hasPermission } from "../../utils";
import Spinner from "../Spinner";

interface Props {
  orgId: string;
  dealId: string;
}

export default function UploadFilesButton({ orgId, dealId }: Props) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const mutation = useCreateDealFileMutation(orgId, dealId);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      throw new Error("No files selected");
    }
    Array.from(e.target.files).forEach(async (file) => {    
      try {
        const password = await checkForPassword(file);
        mutation.mutate({
          file: file,
          password: password,
        });
      } catch (e) {
        console.error(e);
        alert(`"${file.name}" could not be uploaded.`);
      }
    });
    e.target.value = "";
  };

  switch (hasPermission(permissionsQuery, "files.create")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 whitespace-nowrap"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <>
          <input
            className="hidden"
            type="file"
            id="file"
            multiple
            ref={inputRef}
            onChange={handleChange}
          />
          <button
            type="button"
            onClick={handleClick}
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 whitespace-nowrap"
          >
            Upload Files
          </button>
        </>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 whitespace-nowrap"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Upload Files
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 whitespace-nowrap"
          disabled
        >
          Upload Files
        </button>
      );
  }
}
