import { TrashIcon } from "@heroicons/react/20/solid";
import { hasPermission } from "../../../../utils";
import Spinner from "../../../Spinner";
import { useUserDealPermissionsQuery } from "../../../../hooks/userDealRoles";
import { useDeleteCompanyProfileMutation } from "../../../../hooks/companyProfiles";

interface Props {
  orgId: string;
  dealId: string;
  companyProfileId: string;
}

export default function DeleteCompanyProfileButton({
  orgId,
  dealId,
  companyProfileId,
}: Props) {
  const mutation = useDeleteCompanyProfileMutation(
    orgId,
    dealId,
    companyProfileId
  );
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);

  switch (hasPermission(permissionsQuery, "company_profiles.delete")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner h="4" w="4" />
        </button>
      );
    case "true":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => mutation.mutate()}
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
  }
}
