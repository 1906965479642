import { useNavigate, useParams } from "react-router-dom";
import { useDealQuery, useDealUserChatThreadQuery } from "../../../hooks";
import { APIError } from "../../../api/shared";
import { useForm } from "react-hook-form";
import { DealUserChatThreadRead } from "../../../api/dealChatThreads";
import { DealChatThreadExportFileFormat } from "../../../api/dealChatThreadExports";
import { useCreateDealChatThreadExportMutation } from "../../../hooks/dealChatThreadExports";
import TabLayout from "../../TabLayout";

function downloadFromURL(url: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = url;
  anchor.click();
}

type URLParams = {
  orgId: string;
  dealId: string;
  dealChatThreadId: string;
};

interface FormProps {
  orgId: string;
  dealId: string;
  dealChatThread: DealUserChatThreadRead;
}

interface DealChatThreadExportCreateFormValues {
  fileFormat: DealChatThreadExportFileFormat;
  includeCitations: "true" | "false";
}

function DealChatThreadExportsCreateForm({
  orgId,
  dealId,
  dealChatThread,
}: FormProps) {
  const navigate = useNavigate();
  const { handleSubmit, reset, register } =
    useForm<DealChatThreadExportCreateFormValues>({
      defaultValues: {
        fileFormat: "pdf",
        includeCitations: "true",
      },
    });

  const mutation = useCreateDealChatThreadExportMutation(
    orgId,
    dealId,
    dealChatThread.id
  );

  return (
    <form
      onSubmit={handleSubmit((values) => {
        mutation.mutate(
          {
            file_format: values.fileFormat,
            include_citations: values.includeCitations === "true",
          },
          {
            onSuccess: (dealChatThreadExport) => {
              if (process.env.NODE_ENV === "development") {
                downloadFromURL(
                  dealChatThreadExport.download_url.replace(
                    "http://storage",
                    "http://localhost"
                  )
                );
              } else {
                downloadFromURL(dealChatThreadExport.download_url);
              }
              navigate(
                `/orgs/${orgId}/deals/${dealId}/chat-threads/${dealChatThread.id}`
              );
              reset();
            },
          }
        );
      })}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="File Format"
                className="block text-sm font-medium text-gray-700"
              >
                File Format
              </label>
              <div className="mt-1">
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                  {...register("fileFormat")}
                  disabled={mutation.isLoading}
                >
                  <option value={"pdf"}>{"pdf"}</option>
                  <option value={"docx"}>{"docx"}</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="Include Citations"
                className="block text-sm font-medium text-gray-700"
              >
                Include Citations
              </label>
              <div className="mt-1">
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                  {...register("includeCitations")}
                  disabled={mutation.isLoading}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(
                `/orgs/${orgId}/deals/${dealId}/chat-threads/${dealChatThread.id}`
              )
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={mutation.isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={mutation.isLoading}
          >
            Export
          </button>
        </div>
      </div>
    </form>
  );
}

export default function DealChatThreadExportsCreate() {
  const { orgId, dealId, dealChatThreadId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const chatThreadQuery = useDealUserChatThreadQuery(
    orgId,
    dealId,
    dealChatThreadId
  );

  // Loading
  if (chatThreadQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Loading chat thread.</p>
        </ul>
      </div>
    );
  }

  // Insufficient permissions
  if (
    chatThreadQuery.error instanceof APIError &&
    chatThreadQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to view this chat thread."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (chatThreadQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error viewing this chat thread.
          </p>
        </ul>
      </div>
    );
  }

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Chat Threads",
          href: `/orgs/${orgId}/deals/${dealId}/chat-threads`,
        },
        {
          name: chatThreadQuery.data?.name || "New Thread",
          href: `/orgs/${orgId}/deals/${dealId}/chat-threads/${dealChatThreadId}`,
        },
        { name: "Export", href: null },
      ]}
    >
      <div className="p-4">
        <DealChatThreadExportsCreateForm
          orgId={orgId}
          dealId={dealId}
          dealChatThread={chatThreadQuery.data}
        />
      </div>
    </TabLayout>
  );
}
