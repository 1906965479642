import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export const PropertyDataType = {
  String: "text",
  Number: "number",
  Options: "options",
  Date: "date",
  Boolean: "boolean"
};
export type PropertyDataType = (typeof PropertyDataType)[keyof typeof PropertyDataType];

export interface Value {
  content: string;
}

export interface Example {
  content: string;
}

export const defaultExample: Example = {
  content: ""
};

export interface Property {
  name: string;
  data_type: PropertyDataType,
  values: Value[],
  allow_multiple: boolean
  description: string;
  examples: Example[];
}

export const defaultProperty: Property = {
  name: "",
  data_type: PropertyDataType.String,
  values: [],
  allow_multiple: false,
  description: "",
  examples: [defaultExample]
};

export interface CompanyProfileTemplateList {
  id: string;
  org_id: string;
  name: string;
  created_at: string;
}

export const listCompanyProfileTemplates = async (
  user: User,
  orgId: string
): Promise<CompanyProfileTemplateRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/company-profile-templates`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};


export interface CompanyProfileTemplateRead {
  id: string;
  org_id: string;
  name: string;
  properties: Property[];
  created_at: string;
}

export const readCompanyProfileTemplate = async (
  user: User,
  orgId: string,
  companyProfileTemplateId: string
): Promise<CompanyProfileTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/company-profile-templates/${companyProfileTemplateId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface CompanyProfileTemplateCreate {
  name: string;
  properties: Property[];
}

export const createCompanyProfileTemplate = async (
  user: User,
  orgId: string,
  data: CompanyProfileTemplateCreate
): Promise<CompanyProfileTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/company-profile-templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export interface CompanyProfileTemplateUpdate {
  name: string;
  properties: Property[]
}

export const updateCompanyProfileTemplate = async (
  user: User,
  orgId: string,
  companyProfileTemplateId: string,
  data: CompanyProfileTemplateUpdate
): Promise<CompanyProfileTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/company-profile-templates/${companyProfileTemplateId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const deleteCompanyProfileTemplate = async (
  user: User,
  orgId: string,
  companyProfileTemplateId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/company-profile-templates/${companyProfileTemplateId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};
