import { createPortal } from "react-dom";
import {
  File,
  TableAggregationGroup,
} from "../../../../../api/tableAggregationLayouts";
import TableAggregationColumnRender from "./TableAggrgationColumnRender";
import {
  classNames,
  removeEmptyColumnsAndGroups,
  useDragItem,
  useDropZone,
  useScale,
} from "./utils";
import {
  DragItemType,
  DropZoneType,
  TableAggregationGroupWithSelection,
} from "./types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { CheckBox } from "../../../../base/CheckBox";

function ColumnDropZone({
  groupIndex,
  columnIndex,
}: {
  groupIndex: number;
  columnIndex: number;
}) {
  const { ref, isDraggedOver } = useDropZone({
    type: DropZoneType.COLUMN,
    groupIndex,
    columnIndex,
  });
  return (
    <div className="flex" ref={ref}>
      <div className="h-full w-4 flex flex-col items-center">
        {isDraggedOver && <div className="border-l-2 border-black h-full" />}
      </div>
    </div>
  );
}

function deleteGroupItem(
  groupItem: { groupIndex: number },
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>
) {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this group?"
  );
  if (!confirmDelete) return;
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));
    newLayout.splice(groupItem.groupIndex, 1);
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function selectGroupItem(
  groupItem: { groupIndex: number },
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>
) {
  setLayout((prevLayout) => {
    const newLayout = JSON.parse(JSON.stringify(prevLayout));
    newLayout[groupItem.groupIndex].isSelected =
      !newLayout[groupItem.groupIndex].isSelected;
    return newLayout;
  });
}

function TableAggregationGroupRenderInner({
  files,
  group,
  groupIndex,
  setLayout,
}: {
  files: File[];
  group: TableAggregationGroupWithSelection;
  groupIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroupWithSelection[]>>;
}) {
  return (
    <div className="bg-gray-200 flex flex-row pl-4 py-4 rounded-md border border-gray-300 relative">
      <div className="h-full absolute left-2 top-4 flex flex-col space-y-2">
        <button
          onClick={() => {
            deleteGroupItem({ groupIndex }, setLayout);
          }}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
        <CheckBox
          state={group.isSelected ? "checked" : "unchecked"}
          onClick={() => {
            selectGroupItem({ groupIndex }, setLayout);
          }}
        />
      </div>
      <ColumnDropZone groupIndex={groupIndex} columnIndex={0} />
      {group.columns.map((column, index) => (
        <>
          <TableAggregationColumnRender
            files={files}
            column={column}
            groupIndex={groupIndex}
            columnIndex={index}
            setLayout={setLayout}
          />
          <ColumnDropZone groupIndex={groupIndex} columnIndex={index + 1} />
        </>
      ))}
    </div>
  );
}

function TableAggregationGroupRenderPreview(props: {
  files: File[];
  group: TableAggregationGroup;
  groupIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { scaleStyle } = useScale();
  return (
    <div className={classNames(scaleStyle, "opacity-40 origin-top-left")}>
      <TableAggregationGroupRenderInner {...props} />
    </div>
  );
}

export default function TableAggregationGroupRender(props: {
  files: File[];
  group: TableAggregationGroup;
  groupIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { ref, itemState } = useDragItem({
    type: DragItemType.GROUP,
    groupIndex: props.groupIndex,
  });

  return (
    <div ref={ref}>
      <div className={itemState.type === "dragging" ? "opacity-60" : ""}>
        <TableAggregationGroupRenderInner {...props} />
      </div>
      {itemState.type === "preview"
        ? createPortal(
            <TableAggregationGroupRenderPreview {...props} />,
            itemState.container
          )
        : null}
    </div>
  );
}
