import {
  DocumentDuplicateIcon,
  NewspaperIcon,
  UsersIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { PostHog } from 'posthog-js';
export interface NavigationItem {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}

export function getNavigation(orgId: string, pathname: string, posthog: PostHog) {
  const navigation =
    [
      {
        name: "Deals",
        href: `deals`,
        icon: NewspaperIcon,
        current: pathname.startsWith(`/orgs/${orgId}/deals`),
      },
      {
        name: "Templates",
        href: `templates`,
        icon: DocumentDuplicateIcon,
        current: pathname.startsWith(`/orgs/${orgId}/templates`),
      },
      {
        name: "Users",
        href: `users`,
        icon: UsersIcon,
        current: pathname.startsWith(`/orgs/${orgId}/users`),
      },
    ];

  if (posthog.isFeatureEnabled("company_search")) {
    navigation.splice(0, 0, {
      name: "Companies",
      href: `companies`,
      icon: BuildingOffice2Icon,
      current: pathname.startsWith(`/orgs/${orgId}/companies`),
    },);
  }

  return navigation;
}

export interface UserNavigationItem {
  name: string;
  callback: () => void;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}

export function getUserNavigation(logout: () => void) {
  return [
    {
      name: "Logout",
      callback: logout,
      icon: ArrowRightOnRectangleIcon,
      current: false,
    },
  ];
}

export function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export const sidebarBgColor = "bg-gray-50";
export const sidebarHighlightColor = "bg-white";
