import { useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  FileTableGroupMergeExportCreate,
  createFileTableGroupMergeExport,
} from "../api/fileTableGroupMergeExports";

export const useCreateFileTableGroupMergeExportMutation = (
  orgId: string,
  dealId: string,
  fileTableGroupMergeId: string
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: FileTableGroupMergeExportCreate) =>
      createFileTableGroupMergeExport(
        user,
        orgId,
        dealId,
        fileTableGroupMergeId,
        data
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "fileTableGroupMerges",
          fileTableGroupMergeId,
          "exports",
        ],
      });
    },
  });
};
