import { User } from "../types";
import { Question } from "./dealQuestionThreads";
import { baseURL, resolveFetch } from "./shared";

export interface QuestionTemplateList {
  id: string;
  org_id: string;
  name: string;
  created_at: string;
}

export const listQuestionTemplates = async (
  user: User,
  orgId: string
): Promise<QuestionTemplateList[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/question-templates`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface QuestionTemplateRead {
  id: string;
  org_id: string;
  name: string;
  questions: Question[];
  created_at: string;
}

export const readQuestionTemplate = async (
  user: User,
  orgId: string,
  questionTemplateId: string
): Promise<QuestionTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/question-templates/${questionTemplateId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};

export interface QuestionTemplateCreate {
  name: string;
  questions: Question[];
}

export const createQuestionTemplate = async (
  user: User,
  orgId: string,
  data: QuestionTemplateCreate
): Promise<QuestionTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/question-templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export interface QuestionTemplateUpdate {
  name: string;
  questions: Question[];
}

export const updateQuestionTemplate = async (
  user: User,
  orgId: string,
  questionTemplateId: string,
  data: QuestionTemplateUpdate
): Promise<QuestionTemplateRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/question-templates/${questionTemplateId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const deleteQuestionTemplate = async (
  user: User,
  orgId: string,
  questionTemplateId: string
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/question-templates/${questionTemplateId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    })
  );
};
